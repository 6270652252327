<template>
    <div
        class="sq-text my-4 text-label-1"
        ref="question_title_ref"
    >
        {{ question.title }}<span class="sq-text__asterisk">{{ is_required ? "*" : "" }}</span>
    </div>
    <RisifyTextarea
        v-model="question_text_value"
        class-name="mb-2"
        outlined
        :min-length="question.text.min_length"
        :max-length="question.text.max_length"
        :counter="question.text.max_length"
        :rows="12"
        :autogrow="question.text.autogrow"
        @update:model-value="validate"
        show-asterisk
    >
    </RisifyTextarea>
    <p class="sq-text__errmsg">{{ question_error }}</p>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import RisifyTextarea from "@/components/form-inputs/RisifyTextarea.vue";
import { SurveyQuestionText, SurveyQuestionExpose, SurveyQuestionValue } from "@/types/surveys";
import { pluralize } from "@/helpers/pluralizer";

const props = defineProps<{
    question: SurveyQuestionText;
}>();

defineExpose<SurveyQuestionExpose>({
    getValue,
    validate,
    getAnchorElement: () => question_title_ref.value
});

const question_text_value = ref<string>("");
const question_error = ref<string>("");
const question_title_ref = ref<HTMLElement>();
const is_required = computed(() => {
    return props.question.text.min_length > 0;
});

function getValue(): SurveyQuestionValue | null {
    return question_text_value.value.length >= 1
        ? { question: props.question._id, values: [question_text_value.value] }
        : null;
}

function validate(): boolean {
    if (question_text_value.value.length < props.question.text.min_length) {
        if (question_text_value.value.length === 0) {
            question_error.value = "Twoja odpowiedź nie może być pusta";
            return false;
        }

        question_error.value = `Twoja odpowiedź powinna zawierać przynajmniej ${pluralize(
            props.question.text.min_length,
            ["0 znaków", "1 znak", "{n} znaki", "{n} znaków"]
        )} długości.`;
        return false;
    } else if (question_text_value.value.length > props.question.text.max_length) {
        question_error.value = `Twoja odpowiedź nie powinna być dłuższa niż ${props.question.text.max_length} znaków.`;
        return false;
    } else {
        question_error.value = "";
    }
    return true;
}
</script>
