<template>
    <g>
        <path
            d="M 12,1 A 11,11 0 0 0 1,12 11,11 0 0 0 12,23 11,11 0 0 0 23,12 11,11 0 0 0 12,1 Z m 0,1.8945312 A 9.1063471,9.1063471 0 0 1 21.105469,12 9.1063471,9.1063471 0 0 1 12,21.105469 9.1063471,9.1063471 0 0 1 2.8945312,12 9.1063471,9.1063471 0 0 1 12,2.8945312 Z"
        />
        <path
            d="m 12.000001,18.813054 c -0.641025,0 -1.157084,-0.51606 -1.157084,-1.157084 v -6.648989 c 0,-0.641025 0.516059,-1.157084 1.157084,-1.157084 0.641024,0 1.157083,0.516059 1.157083,1.157084 v 6.648989 c 0,0.641024 -0.516059,1.157084 -1.157083,1.157084 z"
        />
        <path
            d="M 10.435389,6.751558 A 1.5646119,1.5646119 0 0 1 12,5.186946 1.5646119,1.5646119 0 0 1 13.564611,6.751558 1.5646119,1.5646119 0 0 1 12,8.316171 1.5646119,1.5646119 0 0 1 10.435389,6.751558 Z"
        />
    </g>
</template>
