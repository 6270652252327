<template>
    <g clip-path="url(#clip0_1215_4628)">
        <path
            d="M5.31891 14.5035L4.4835 17.6222L1.43011 17.6868C0.517594 15.9943 0 14.0578 0 12C0 10.0101 0.483938 8.13362 1.34175 6.48132H1.34241L4.06078 6.9797L5.25159 9.68176C5.00236 10.4084 4.86652 11.1884 4.86652 12C4.86661 12.8809 5.02617 13.7249 5.31891 14.5035Z"
            fill="#FBBB00" />
        <path
            d="M23.7903 9.75824C23.9281 10.4841 24 11.2338 24 12C24 12.8591 23.9097 13.6971 23.7376 14.5055C23.1534 17.2563 21.627 19.6582 19.5125 21.358L19.5119 21.3574L16.088 21.1827L15.6034 18.1576C17.0064 17.3347 18.1029 16.047 18.6805 14.5055H12.2638V9.75824H18.7741H23.7903Z"
            fill="#518EF8" />
        <path
            d="M19.5118 21.3574L19.5125 21.358C17.456 23.011 14.8437 24 11.9999 24C7.43005 24 3.45688 21.4457 1.43005 17.6868L5.31885 14.5035C6.33224 17.2081 8.94126 19.1334 11.9999 19.1334C13.3146 19.1334 14.5463 18.778 15.6032 18.1576L19.5118 21.3574Z"
            fill="#28B446" />
        <path
            d="M19.6596 2.76262L15.7721 5.94525C14.6783 5.26153 13.3853 4.86656 12 4.86656C8.87213 4.86656 6.21431 6.88017 5.25169 9.68175L1.34245 6.48131H1.3418C3.33895 2.63077 7.36223 0 12 0C14.9117 0 17.5814 1.03716 19.6596 2.76262Z"
            fill="#F14336" />
    </g>
</template>
