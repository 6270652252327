<template>
    <div
        :class="{
            'dialog-base-body text-link-2 text-md-link-1 px-6 pt-3 pt-md-6': true,
            'dialog-base-body--flex-grow': flexGrow,
            'dialog-base-body--sssh': show_overflow_bottom_shadow
        }"
        ref="main_el_ref"
    >
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, onMounted, onUnmounted, ref } from "vue";
import { DialogExpose } from "./Dialog.vue";

withDefaults(
    defineProps<{
        flexGrow?: boolean;
    }>(),
    {
        flexGrow: false
    }
);

const main_el_ref = ref<HTMLElement>();
const show_overflow_bottom_shadow = ref<boolean>(false);
const parent_dialog_el = ref<HTMLElement>();

function onDialogMainElScroll(this: HTMLElement) {
    if (this.scrollHeight > this.clientHeight) {
        if (Math.abs(this.scrollHeight - this.scrollTop - this.clientHeight) < 1) {
            show_overflow_bottom_shadow.value = false;
        } else {
            show_overflow_bottom_shadow.value = true;
        }
    } else {
        show_overflow_bottom_shadow.value = false;
    }
}

function setupParentDialogEvents() {
    if (!parent_dialog_el.value) return;
    parent_dialog_el.value.addEventListener("scroll", onDialogMainElScroll, { passive: true });
}
function clearParentDialogEvents() {
    if (!parent_dialog_el.value) return;

    parent_dialog_el.value.removeEventListener("scroll", onDialogMainElScroll);
}

onMounted(() => {
    let instance = getCurrentInstance();
    let found: false | DialogExpose = false;
    while (found === false && instance && instance.parent !== null) {
        if (instance.type.name === "Dialog" && instance.exposed !== null) {
            found = instance.exposed as DialogExpose;
        }
        instance = instance.parent;
    }

    if (found) {
        parent_dialog_el.value = found.getMainElement();
        setupParentDialogEvents();
        if (parent_dialog_el.value) {
            onDialogMainElScroll.call(parent_dialog_el.value);
        }
    }
});

onUnmounted(() => {
    clearParentDialogEvents();
});
</script>
