export const colors: Record<string, string> = {
    primary: "#64A286",
    primary_light: "#E6F0E4",
    primary_dark: "#113A29",
    secondary: "#FFBF52",
    secondary_light: "#FFEADD",
    secondary_dark: "#DB9B60",
    error: "#ec2f23",
    success: "#14B8A6",
    red_accent: "#C2664E",
    gray_accent: "#f3f5f4",
    default: "#1a1a1a",
    white: "#fff"
};

export const APP_DOMAIN = "https://app.risify.pl";
