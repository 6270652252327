<template>
    <div id="app">
        <AutoUpdater />
        <DefaultLayout>
            <router-view
                v-if="generalStore.app_booted"
                v-slot="{ Component }"
                key="app-booted"
            >
                <transition
                    name="fade"
                    mode="out-in"
                    appear
                >
                    <component
                        :is="Component"
                        :key="route_key"
                    />
                </transition>
            </router-view>
            <div
                v-else
                class="app"
                key="app-not-booted"
            >
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        class="app-loading"
                        v-if="generalStore.is_online"
                        key="app-loading"
                    >
                        <div class="app-loading__spinner">
                            <Spinner
                                color="primary"
                                size="24"
                                width="4"
                            />
                        </div>
                        <div
                            class="app-loading__text"
                            v-t="'App.loading'"
                        ></div>
                    </div>
                </Transition>
            </div>
        </DefaultLayout>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import { useNotivue } from "notivue";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { Preferences } from "@capacitor/preferences";
import { Style } from "@capacitor/status-bar";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { SplashScreen } from "@capacitor/splash-screen";

import { useGeneralStore } from "./stores/general";
import { useBreakpoints } from "./helpers/breakpoints";
import { useStatusBar } from "./helpers/statusbar";
import { Stopwatch } from "./helpers/waiters";
import { useAuthStore } from "./stores/auth";
import { clearUnusedCaches } from "./plugins/browserCache";

import DefaultLayout from "./layouts/DefaultLayout.vue";
import Spinner from "@/components/loaders/Spinner.vue";
import AutoUpdater from "@/components/generics/AutoUpdater.vue";

import { AuthData } from "./types/auth";
import { usei18n } from "./plugins/i18n";

/*###########
### SETUP ###
########## */
const route = useRoute();
const generalStore = useGeneralStore();
const authStore = useAuthStore();
const { is_desktop } = useBreakpoints();
const { locale, t } = usei18n();
const { setStatusBarStyle } = useStatusBar();

useHead({
    title: t("global.meta.generic_title"),
    titleTemplate: t("global.meta.title_template"),
    htmlAttrs: {
        lang: locale
    }
});

/*#############
### GENERAL ###
############ */
const CAPACITOR_EVENT_LISTENERS_HANDLES: PluginListenerHandle[] = [];

const route_key = computed(() => {
    if (route.name === "quiz" || route.name === "quiz-board") return "quiz";
    if (
        route.name === "my-account-general-data" ||
        route.name === "my-account-login-data-and-security" ||
        route.name === "my-account-notifications" ||
        route.name === "my-account-payments" ||
        route.name === "my-account-recommend-friends" ||
        route.name === "my-account-recommend-friends-sent" ||
        route.name === "my-account-linked-users" ||
        route.name === "my-account-experience" ||
        route.name === "my-account-education" ||
        route.name === "my-account-products" ||
        route.name === "my-account-availability" ||
        route.name === "my-account-settings"
    ) {
        return "my-account";
    }
    return route.fullPath;
});

/*#############
### NOTIVUE ###
############ */
const notivueConfig = useNotivue();

watch(
    is_desktop,
    () => {
        if (is_desktop.value) {
            notivueConfig.position.value = "bottom-left";
        } else {
            notivueConfig.position.value = "top-center";
        }
    },
    { immediate: true }
);

/*####################
### NETWORK STATUS ###
################### */
watch(
    () => generalStore.is_online,
    (nv, ov) => {
        if (nv && !generalStore.app_booted) {
            appBoot();
        }

        if (nv && !ov) {
            if (authStore.user_data_invalidated) {
                authStore.fetchUserData();
            }
        }
    }
);

function onOnline() {
    generalStore.setIsOnline(true);
}

function onOffline() {
    generalStore.setIsOnline(false);
}

/*########################
### SCREEN ORIENTATION ###
####################### */
const lockScreenOrientation = async () => {
    try {
        await ScreenOrientation.lock({ orientation: "portrait" });
    } catch (error) {
        console.error(error);
    }
};

/*##############
### APP BOOT ###
############# */
async function appBoot() {
    if (generalStore.app_booted) return;

    const SW = new Stopwatch();

    // 1. Cache clear
    await clearUnusedCaches();

    // 2. Nasłuchujemy zmiany statusu sieci
    if (Capacitor.isNativePlatform()) {
        Network.addListener("networkStatusChange", async status => {
            generalStore.setIsOnline(status.connected);
        }).then(h => CAPACITOR_EVENT_LISTENERS_HANDLES.push(h));
    } else {
        window.addEventListener("online", onOnline);
        window.addEventListener("offline", onOffline);
    }

    // 3. Ustalamy initial state dla is_online
    if (Capacitor.isNativePlatform()) {
        const NETWORK_STATUS = await Network.getStatus();
        generalStore.setIsOnline(NETWORK_STATUS.connected);
    } else {
        generalStore.setIsOnline(true);
    }

    // 4. Staramy się przywrócić sesję przy pomocy danych z LS
    let auth_data: undefined | AuthData = undefined;

    if (Capacitor.isNativePlatform()) {
        const AD = await Preferences.get({ key: authStore.AD_PREFERENCES_KEY });
        if (AD.value) {
            try {
                auth_data = JSON.parse(AD.value);
            } catch (err) {
                console.error(err);
            }
        }
    } else {
        auth_data = authStore.auth_data;
    }

    if (auth_data && authStore.isAuthDataValid(auth_data)) {
        try {
            await authStore.logUserIn(auth_data);
        } catch (err) {
            console.error(err);
        }
    } else {
        if (Capacitor.isNativePlatform()) {
            await Preferences.remove({ key: authStore.AD_PREFERENCES_KEY });
        }
    }

    // 5. Oznaczamy w store, że mamy wszystko ogarnięte
    await SW.waitUntil(350);
    generalStore.setAppBooted(true);
}

onMounted(async () => {
    if (Capacitor.isNativePlatform()) {
        try {
            await SplashScreen.hide();
            await lockScreenOrientation();
        } catch (err) {
            console.error(err);
        }
    }
    await setStatusBarStyle(Style.Light);
    appBoot();
    await generalStore.app_boot_promise;
});

onUnmounted(() => {
    CAPACITOR_EVENT_LISTENERS_HANDLES.forEach(h => h.remove());
});
</script>
