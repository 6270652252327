<template>
    <path
        d="M4 4C4 2.89543 4.89543 2 6 2H15L20 7V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4Z"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 3C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V8H16C14.8954 8 14 7.10457 14 6V3H6ZM16 4.41421L17.5858 6H16V4.41421ZM3 4C3 2.34315 4.34315 1 6 1H15C15.2652 1 15.5196 1.10536 15.7071 1.29289L20.7071 6.29289C20.8946 6.48043 21 6.73478 21 7V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V4Z"
    />
    <path
        d="M20 7L15 2V6C15 6.55228 15.4477 7 16 7H20Z"
        fill="#113A29"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6173 1.07615C14.991 0.921369 15.4211 1.00692 15.7071 1.29292L20.7071 6.29292C20.9931 6.57892 21.0787 7.00904 20.9239 7.38271C20.7691 7.75639 20.4045 8.00003 20 8.00003H16C14.8954 8.00003 14 7.1046 14 6.00003V2.00003C14 1.59557 14.2436 1.23093 14.6173 1.07615ZM16 4.41424V6.00003H17.5858L16 4.41424Z"
        fill="#113A29"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 14C7 13.4477 7.44772 13 8 13H16C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15H8C7.44772 15 7 14.5523 7 14Z"
        fill="#113A29"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 18C7 17.4477 7.44772 17 8 17H12C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19H8C7.44772 19 7 18.5523 7 18Z"
        fill="#113A29"
    />
</template>
