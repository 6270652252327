<template>
    <div
        :class="{
            'global-wrapper': !noWrapper,
            'top-navigation__wrapperghost': noWrapper
        }"
    >
        <div
            class="top-navigation text-h7"
            :class="[
                {
                    'top-navigation--left text-left': alignment === 'left',
                    'top-navigation--center text-center px-12': alignment === 'center',
                    'pl-0 pr-0': !hasSlot('prepend') && !hasSlot('append')
                }
            ]"
        >
            <div class="top-navigation__prepend">
                <slot name="prepend" />
            </div>
            <div class="top-navigation__main text-h7 text-weight-semibold">
                <slot name="main" />
            </div>
            <div
                class="top-navigation__append"
                :class="!hasSlot('main') && 'top-navigation--menu text-link-2'"
            >
                <slot name="append" />
            </div>
            <div
                v-if="progressValue"
                class="top-navigation__progress-bar"
                :style="{ width: progressValue + '%' }"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

const slots = useSlots();

const hasSlot = (name: string) => {
    return !!slots[name];
};

type TopNavigationProps = {
    alignment?: "left" | "center";
    progressValue?: number;
    noWrapper?: boolean;
};

withDefaults(defineProps<TopNavigationProps>(), {
    alignment: "center",
    noWrapper: false
});
</script>
