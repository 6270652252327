<template>
    <!-- @vue-expect-error Generyki nie przekazują się tak, jak powinny -->
    <RisifyInput
        :hint="hint"
        :rules="rules"
        :value="modelValue"
        v-model:error="error_state"
        ref="custom_input_ref"
    >
        <div
            class="risify-checkbox"
            :class="{
                ['risify-checkbox--' + variant]: true,
                'risify-checkbox--outlined': variant != 'default' && outlined,
                'risify-checkbox--checked': cbe_ref?.is_checked,
                'risify-checkbox--disabled': disabled,
                'risify-checkbox--align-center': alignCenter,
                'risify-checkbox--invalid': error_state
            }"
            @click.stop="cbe_ref?.toggleElement()"
        >
            <div
                v-if="variant === 'image-tile'"
                class="risify-checkbox__image"
            >
                <LazyImage
                    v-if="imageSrc"
                    :src="imageSrc"
                    :lazy-src="imageLazySrc ? imageLazySrc : imageSrc"
                />
            </div>
            <div class="risify-checkbox__main">
                <!-- @vue-skip -->
                <RisifyCheckboxElement
                    :disabled="disabled"
                    :multiple="multiple"
                    :error="error_state"
                    :true-value="trueValue"
                    :model-value="modelValue"
                    @update:model-value="onRceMvUpdate"
                    class="risify-checkbox__control"
                    ref="cbe_ref"
                    :small="variant === 'micro-tile'"
                    :style="hideIcon ? { display: 'none' } : {}"
                />
                <div
                    class="risify-checkbox__label"
                    :class="{
                        'text-link-1': variant !== 'micro-tile',
                        'text-link-2': variant === 'micro-tile'
                    }"
                    v-if="hasSlot('label') || label != ''"
                >
                    <slot
                        name="label"
                        v-if="hasSlot('label')"
                    ></slot>
                    <template v-else>
                        {{ label }}
                    </template>
                </div>
            </div>
        </div>
    </RisifyInput>
</template>

<script setup lang="ts" generic="T">
import { nextTick, ref, useSlots } from "vue";

import RisifyInput, { RisifyInputProps } from "@/components/form-inputs/RisifyInput.vue";
import RisifyCheckboxElement, {
    RisifyCheckboxElementProps,
    RisifyCheckboxElementValue
} from "@/components/form-inputs/RisifyCheckboxElement.vue";
import LazyImage from "@/components/generics/LazyImage.vue";

export type RisifyCheckboxProps<T> = Omit<
    RisifyInputProps<T>,
    "id" | "counter" | "value" | "showAsterisk" | "error" | "className" | "disabled" | "dense"
> &
    Omit<RisifyCheckboxElementProps<T>, "readonly" | "xsmall" | "small" | "large" | "xlarge"> & {
        variant?: "default" | "tile" | "micro-tile" | "image-tile";
        hideIcon?: boolean;
        imageSrc?: string;
        imageLazySrc?: string;
        outlined?: boolean;
        alignCenter?: boolean;
    };

/*###########
### SETUP ###
###########*/
withDefaults(defineProps<RisifyCheckboxProps<T>>(), {
    variant: "default",
    hideIcon: false
});

const emit = defineEmits<{
    (e: "update:modelValue", value: RisifyCheckboxElementValue<T>): void;
}>();

defineOptions({
    inheritAttrs: false
});

defineExpose({
    validate,
    resetValidation
});

/*###########
### HOOKS ###
###########*/
const slots = useSlots();

/*###############
### VARIABLES ###
###############*/
const custom_input_ref = ref<any>();
const cbe_ref = ref<any>();
const error_state = ref<boolean>(false);

/*##############
### COMPUTED ###
##############*/

/*##############
### WATCHERS ###
##############*/

/*#############
### METHODS ###
#############*/
// @ts-ignore
function onRceMvUpdate(v: RisifyCheckboxElementValue<T>) {
    emit("update:modelValue", v);
    nextTick(validate);
}

function validate() {
    return custom_input_ref.value && custom_input_ref.value.validate();
}
function resetValidation() {
    return custom_input_ref.value.resetValidation();
}

function hasSlot(name: string) {
    return !!slots[name];
}
</script>
