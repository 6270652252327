import { ChatMessageLogEntry } from "@/types/chat";
import {
    ChatMessage,
    ChatMessageAuthorType,
    ChatMessageEventNameLabel,
    ChatMessageMessageType
} from "@/types/chat-message";

export function shouldReportChatMessageToMessageLog(
    chat_msg: ChatMessage,
    msg_log: Record<string, ChatMessageLogEntry>,
    user_id: string
): "report" | "already_reported" | "dont_report" {
    if (chat_msg.author_type === ChatMessageAuthorType.USER && user_id === chat_msg.user._id)
        return "dont_report";

    const entry = msg_log[user_id];

    if (entry !== undefined && entry.mid >= chat_msg._id) return "already_reported";

    return "report";
}

export function getChatMessageContentAsText(chat_message: ChatMessage): string {
    if (chat_message.deleted !== false) return "Wiadomość usunięta";
    if (chat_message.message_type === ChatMessageMessageType.TEXT && chat_message.text.content) {
        return chat_message.text.content;
    }
    if (chat_message.message_type === ChatMessageMessageType.HTML) {
        return "Niewspierany format wiadomości";
    }
    if (chat_message.message_type === ChatMessageMessageType.FILE) {
        return `Przesłano plik ${chat_message.file.name}`;
    }
    if (chat_message.message_type === ChatMessageMessageType.EVENT) {
        return ChatMessageEventNameLabel[chat_message.event.name];
    }
    return "";
}

export function isChatMessageRead(
    lr_msg: Record<string, ChatMessageLogEntry>,
    user_id: string,
    msg?: ChatMessage
): boolean {
    if (!msg) return true;

    if (msg.author_type === ChatMessageAuthorType.USER && msg.user._id === user_id) {
        return true;
    }

    const entry = lr_msg[user_id];

    if (entry !== undefined && entry.mid >= msg._id) {
        return true;
    }

    return false;
}
