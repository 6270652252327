<template>
    <path
        d="m 9.7404221,16.51542 c 1.6218749,0 3.1537349,-0.574935 4.3728149,-1.620742 l 5.926058,5.940674 c 0.109695,0.110265 0.254272,0.165398 0.398295,0.165398 0.143452,0 0.287467,-0.05513 0.39717,-0.164273 0.21996,-0.219397 0.220522,-0.575497 7.5e-4,-0.795464 L 14.90439,14.095837 C 17.124832,11.443905 16.99488,7.47276 14.507782,4.979475 13.23414,3.7024575 11.54082,2.99925 9.7394846,2.99925 c -1.8013275,0 -3.4946475,0.7032075 -4.7682975,1.980225 -2.6283,2.6350575 -2.6283,6.921795 0,9.556282 1.2742125,1.276463 2.9675325,1.979663 4.76886,1.979663 z m -3.9717,-10.741605 c 1.060995,-1.0638075 2.47134,-1.6494375 3.9717,-1.6494375 1.5003599,0 2.9107129,0.58563 3.9717079,1.6494375 2.191185,2.1968175 2.191185,5.770792 0,7.967602 -1.060995,1.063808 -2.471348,1.649438 -3.9717079,1.649438 -1.50036,0 -2.910705,-0.58563 -3.9717,-1.649438 -2.1911925,-2.197372 -2.1911925,-5.7707845 0,-7.967602 z"
    />
    <path
        d="M 7.0918721,10.329465 H 12.59094 c 0.310537,0 0.56256,-0.251468 0.56256,-0.5625675 0,-0.3110925 -0.252023,-0.56256 -0.56256,-0.56256 H 7.0918721 c -0.3105375,0 -0.5625675,0.2514675 -0.5625675,0.56256 0,0.3110995 0.2514675,0.5625675 0.5625675,0.5625675 z"
    />
    <path
        d="M 10.430524,12.413728 V 6.9146596 c 0,-0.310537 -0.251468,-0.56256 -0.5625673,-0.56256 -0.3110929,0 -0.5625599,0.252023 -0.5625599,0.56256 v 5.4990684 c 0,0.310537 0.251467,0.562567 0.5625599,0.562567 0.3110993,0 0.5625673,-0.251467 0.5625673,-0.562567 z"
    />
</template>
