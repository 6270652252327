<template>
    <div
        class="sq-nps-title my-4 text-label-1"
        ref="question_title_ref"
    >
        {{ question.title }}<span class="sq-nps__asterisk">{{ is_required ? "*" : "" }}</span>
    </div>
    <div class="sq-nps">
        <p class="sq-nps__label sq-nps__label--left">
            {{ question.nps.min_value_label }}
        </p>
        <div
            v-if="question.nps.layout === 'default'"
            :class="{ 'sq-nps__grid': true, 'sq-nps__grid--dense': question.nps.max_value > 5 }"
        >
            <div
                :class="{ 'sq-nps__item': true, 'sq-nps__item--dense': question.nps.max_value > 5 }"
                v-for="n in question.nps.max_value"
                :key="n"
            >
                <RisifyRadio
                    outlined
                    variant="tile"
                    :true-value="n.toString()"
                    v-model="question_nps_value"
                    @update:model-value="validate()"
                >
                    <template #label>
                        {{ n.toString() }}
                        <span
                            class="sq-nps__mlabel"
                            v-if="
                                (n === question.nps.min_value && question.nps.min_value_label) ||
                                (n === question.nps.max_value && question.nps.max_value_label)
                            "
                        >
                            -
                            {{
                                n === question.nps.min_value
                                    ? question.nps.min_value_label
                                    : question.nps.max_value_label
                            }}
                        </span>
                    </template>
                </RisifyRadio>
            </div>
        </div>
        <div v-else-if="question.nps.layout === 'stars'">
            <div
                :class="{
                    'sq-nps-stars__container': true,
                    'sq-nps-stars__container--dense': question.nps.max_value > 5
                }"
            >
                <div
                    v-for="v in question.nps.max_value"
                    :class="{
                        'sq-nps-stars__item': true,
                        'sq-nps-stars__item--active':
                            active_star && v > question.nps.max_value - active_star,
                        'sq-nps-stars__item--dense': question.nps.max_value > 5
                    }"
                    :key="v"
                    @click="
                        active_star = question.nps.max_value - v + 1;
                        question_nps_value = (question.nps.max_value - v + 1).toString();
                        validate();
                    "
                >
                    <CustomIcon
                        v-if="active_star && v > question.nps.max_value - active_star"
                        name="star-filled"
                    />
                    <CustomIcon
                        v-else
                        name="star"
                    />
                </div>
            </div>
        </div>
        <p
            class="sq-nps__label sq-nps__label--right"
            v-if="question.nps.max_value_label"
        >
            {{ question.nps.max_value_label }}
        </p>
    </div>
    <p class="sq-nps__errmsg">{{ question_error }}</p>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import RisifyRadio from "@/components/form-inputs/RisifyRadio.vue";
import { SurveyQuestionNps, SurveyQuestionExpose, SurveyQuestionValue } from "@/types/surveys";

const props = defineProps<{
    question: SurveyQuestionNps;
}>();

defineExpose<SurveyQuestionExpose>({
    getValue,
    validate,
    getAnchorElement: () => question_title_ref.value
});

const question_nps_value = ref<string>("");
const question_error = ref<string>("");
const question_title_ref = ref<HTMLElement>();
const active_star = ref<number | null>(null);
const is_required = computed(() => {
    return props.question.nps.required;
});

function getValue(): SurveyQuestionValue | null {
    return question_nps_value.value.length >= 1
        ? { question: props.question._id, values: [question_nps_value.value] }
        : null;
}

function validate(): boolean {
    if (question_nps_value.value === "" && is_required.value) {
        question_error.value = "Odpowiedź jest wymagana";
        return false;
    } else {
        question_error.value = "";
    }
    return true;
}
</script>
