import { ApiCursorPaginatedResponse, ApiSuccessResponse } from "./api-http-requests";
import { UserPublicDataFragment } from "./user";
import { SelfdevProgramContractPublicDataFragment } from "./selfdev-program-contract"

export enum UserDiscountAffiliateCampaign {
    AF_4X15_1 = "af_4x15_1"
}

export enum UserDiscountValueType {
    PERCENT = "percent",
    AMOUNT = "amount"
}

export enum UserDiscountSource {
    AFFILIATE = "affiliate",
    CUSTOM = "custom",
    SELFDEV_PROGRAM_CONTRACT = "sdp_contract"
}

export enum UserDiscountAffiliateParty {
    RECOMMENDER = "recommender",
    NEW_CLIENT = "new_client"
}

type UserDiscountAffiliateDetails = {
    party: UserDiscountAffiliateParty;
    ref: UserPublicDataFragment;
    campaign: UserDiscountAffiliateCampaign;
    source: string;
};

type UserCustomDiscountDetails = {
    name: string;
};

type UserSelfdevProgramContractDetails = {
    ref: SelfdevProgramContractPublicDataFragment;
    participant: string;
};

type UserDiscountClogEntry = {
    name: UserDiscountClogEntryName;
    user?: string;
    ts: number;
    details: string;
};

type UserDiscountBase = {
    _id: string;
    user: string;
    source: UserDiscountSource;
    value_type: UserDiscountValueType;
    value: number;
    expdate: number;
    quantity: number;
    apl_rules: string;
    clog: UserDiscountClogEntry[];
    deleted: boolean;
    c_date: number;
    m_date: number;
};

type UserDiscountAffiliate = UserDiscountBase & {
    source: UserDiscountSource.AFFILIATE;
    affiliate: UserDiscountAffiliateDetails;
};

type UserDiscountCustom = UserDiscountBase & {
    source: UserDiscountSource.CUSTOM;
    custom: UserCustomDiscountDetails;
};

type UserDiscountSelfdevProgramContract = UserDiscountBase & {
    source: UserDiscountSource.SELFDEV_PROGRAM_CONTRACT;
    sdp_contract: UserSelfdevProgramContractDetails;
};

export type UserDiscount = UserDiscountAffiliate | UserDiscountCustom | UserDiscountSelfdevProgramContract;

/* #########
### CLOG ###
######### */
export enum UserDiscountClogEntryName {
    CREATED = "created",
    DELETED = "deleted",
    UPDATED = "updated",
    USED = "used"
}

export const UserDiscountClogEntryNameLabel = Object.freeze({
    [UserDiscountClogEntryName.CREATED]: "Aktywna",
    [UserDiscountClogEntryName.DELETED]: "Usunięta",
    [UserDiscountClogEntryName.UPDATED]: "Zaktualizowana",
    [UserDiscountClogEntryName.USED]: "Wygasła"
});

export enum UserDiscountClogEntryPredefinedDetail {
    DELETED_AFTER_EXPDATE = "deleted_after_expdate",
    DELETED_AFTER_FULLY_USED = "deleted_after_fully_used"
}

/* ##############
### APL RULES ###
############## */
const UserDiscountAplRuleName = Object.freeze({
    PRODUCT_TYPE: "product_type",
    PRODUCT_SUBTYPE: "product_subtype",
    PRODUCT_ID: "product_id"
});
const UserDiscountAplRuleExpr = Object.freeze({
    EQ: "eq",
    NEQ: "neq",
    IN: "in",
    NIN: "nin",
    LTE: "lte",
    LT: "lt",
    GTE: "gte",
    GT: "gt"
});
export const UserDiscountAplRuleAllowedExprPerRuleName = Object.freeze({
    [UserDiscountAplRuleName.PRODUCT_TYPE]: [
        UserDiscountAplRuleExpr.EQ,
        UserDiscountAplRuleExpr.NEQ,
        UserDiscountAplRuleExpr.IN,
        UserDiscountAplRuleExpr.NIN
    ],
    [UserDiscountAplRuleName.PRODUCT_SUBTYPE]: [
        UserDiscountAplRuleExpr.EQ,
        UserDiscountAplRuleExpr.NEQ,
        UserDiscountAplRuleExpr.IN,
        UserDiscountAplRuleExpr.NIN
    ],
    [UserDiscountAplRuleName.PRODUCT_ID]: [
        UserDiscountAplRuleExpr.EQ,
        UserDiscountAplRuleExpr.NEQ,
        UserDiscountAplRuleExpr.IN,
        UserDiscountAplRuleExpr.NIN
    ]
});

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiUserDiscountResponse = ApiSuccessResponse & {
    user_discount: UserDiscount;
};
export type ApiUserDiscountsResponse = ApiCursorPaginatedResponse & {
    user_discounts: UserDiscount[];
};
