<template>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 4C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V9H15C13.8954 9 13 8.10457 13 7V4H7ZM15 5.41421L16.5858 7H15V5.41421ZM4 5C4 3.34315 5.34315 2 7 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V5Z"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 17C8 16.4477 8.44772 16 9 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H9C8.44772 18 8 17.5523 8 17Z"
    />
</template>
