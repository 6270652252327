/**
 * Metoda zwraca index elementu z tablicy odmian dla podanej liczby wejściowej
 * @param {number} input liczba wejściowa
 * @returns {number} index elementu z tablicy odmian
 */
export function polishPluralizeResolver(input: number) {
    if (input === 0) return 0;
    if (input === 1) return 1;

    const teen = input > 10 && input < 20;
    const endsWithTwoThreeOrFour = input % 10 >= 2 && input % 10 <= 4;

    if (teen || (!teen && !endsWithTwoThreeOrFour)) return 3;
    return 2;
}

export function pluralize(
    n: number = 0,
    choices: string[] = [],
    resolver = polishPluralizeResolver
) {
    if (isNaN(n)) return "Given n is a NaN";
    const CHOICE = resolver(n);
    if (CHOICE < 0 || CHOICE > choices.length) return "`choices` does not have resolved index";
    return choices[CHOICE].replace(/\{ *n *\}/g, n.toString());
}
