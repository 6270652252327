<template>
    <div class="autoupdater">
        <Dialog
            :model-value="update_available_dialog"
            persistent
            width="520"
        >
            <template #header>
                <DialogBaseHeader
                    close-button
                    @close="update_available_dialog = false"
                >
                    Dostępna aktualizacja
                </DialogBaseHeader>
            </template>
            <template #default>
                <DialogBaseBody>
                    <div class="text-body-2 text-md-body-1">
                        Korzystasz ze starej wersji aplikacji. Kliknij poniżej, aby pobrać najnowszą
                        wersję zawierającą nowe funkcjonalności i&nbsp;poprawki błędów.
                    </div>
                </DialogBaseBody>
            </template>
            <template #footer>
                <DialogBaseFooter>
                    <RisifyButton
                        color="green"
                        @click="updateApp"
                        class="ml-auto"
                    >
                        Aktualizuj
                    </RisifyButton>
                </DialogBaseFooter>
            </template>
        </Dialog>

        <Dialog
            :model-value="update_dialog"
            persistent
            width="480"
        >
            <template #header>
                <DialogBaseHeader> Aktualizowanie aplikacji </DialogBaseHeader>
            </template>
            <template #default>
                <DialogBaseBody>
                    <template v-if="!update_completed">
                        Trwa pobieranie nowej wersji aplikacji...
                    </template>
                    <div v-if="update_completed">
                        <div class="text-center mb-4">Proszę czekać...</div>
                        <div class="d-flex justify-center mb-4">
                            <Spinner
                                color="primary"
                                size="32"
                                width="4"
                                class="mx-auto"
                            />
                        </div>
                    </div>
                    <div
                        class="d-flex align-center mt-4 mb-4"
                        v-else
                    >
                        <ProgressBar
                            :value="update_dialog_progress"
                            class="px-0 mt-2"
                            style="flex-shrink: 2"
                        />
                        <span
                            style="width: 48px"
                            class="text-right primary--text"
                        >
                            {{ update_dialog_progress }}%
                        </span>
                    </div>
                </DialogBaseBody>
            </template>
        </Dialog>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { lt, valid as isValidSemverVersion } from "semver";
import { Capacitor } from "@capacitor/core";

import Dialog from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseBody from "@/components/dialogs/DialogBaseBody.vue";
import DialogBaseFooter from "@/components/dialogs/DialogBaseFooter.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import ProgressBar from "@/components/loaders/ProgressBar.vue";
import Spinner from "@/components/loaders/Spinner.vue";
import { Stopwatch, waitForMs } from "@/helpers/waiters";
import { appIsFocused, waitForDocumentReady } from "@/helpers/generics";
import { emitter } from "@/plugins/eventEmitter";
import { Preferences } from "@capacitor/preferences";
import { PREFERENCES_KEYS } from "@/stores/constants";

let remote_document: null | Document = null;

/*###################
### INNER HELPERS ###
###################*/
async function fetchHTML(path: string) {
    const R = await fetch(path);
    const HTML = await R.text();
    const PARSER = new DOMParser();
    return PARSER.parseFromString(HTML, "text/html");
}
function getAppVersionFromHTML(doc: Document) {
    const RV_TAG = doc.querySelector('meta[name="app-version"]');
    let v = "0.0.0";
    if (RV_TAG) {
        const TV = RV_TAG.getAttribute("content");
        if (TV && isValidSemverVersion(TV)) {
            v = TV;
        }
    }
    return v;
}

/*#######################
### PERFORMING UPDATE ###
#######################*/
const performing_update = ref<boolean>(false);
const update_completed = ref<boolean>(false);
let update_start_time = new Stopwatch();

const update_dialog = ref<boolean>(false);
const update_dialog_progress = ref<number>(0);
const update_dialog_assets_count = ref<number>(0);
const update_dialog_assets_count_loaded = ref<number>(0);

function getAssets() {
    if (!remote_document) return [];

    const ASSETS = [...remote_document.querySelectorAll("link[href], script[src]")];

    return ASSETS.filter(el => {
        const ATTR = el.getAttribute(el.tagName === "LINK" ? "href" : "src");
        return (
            ATTR && ATTR.startsWith("/static") && (ATTR.endsWith(".css") || ATTR.endsWith(".js"))
        );
    })
        .map(el => {
            return {
                path: el.getAttribute(el.tagName === "LINK" ? "href" : "src"),
                tag: el.tagName === "LINK" ? "link" : "script",
                is_module_script: el.tagName !== "LINK" && el.getAttribute("type") === "module"
            };
        })
        .filter((a, index, self) => index === self.findIndex(b => b.path === a.path));
}

async function handleUpdateProgress() {
    if (update_dialog_assets_count_loaded.value === update_dialog_assets_count.value) {
        // Finished
        await waitForMs(600);
        await update_start_time.waitUntil(1300);
        update_dialog_progress.value = 100;
        await waitForMs(800);
        update_completed.value = true;
        window.location.reload();
    } else {
        // In Progress
        update_dialog_progress.value += Math.floor(80 / update_dialog_assets_count.value);
    }
}
function reportAssetUpdateProgress() {
    update_dialog_assets_count_loaded.value++;
    handleUpdateProgress();
}

async function updateApp() {
    if (performing_update.value) return;
    performing_update.value = true;
    update_start_time = new Stopwatch();

    update_available_dialog.value = false;
    update_dialog.value = true;
    update_dialog_progress.value = 0;

    // 1. Na początku ustawiamy na 20%
    await waitForMs(250);
    update_dialog_progress.value = 20;

    // 2. Zbieramy wszystkie assety *.js oraz *.css
    const ASSETS = getAssets();
    update_dialog_assets_count.value = ASSETS.length;

    // 3. Pobieramy assety
    ASSETS.forEach(a => {
        let el;

        if (a.tag === "link") {
            el = document.createElement("link");
            el.rel = "stylesheet";
            if (a.path) el.href = a.path;
        } else if (a.tag === "script") {
            el = document.createElement("script");
            if (a.is_module_script) {
                el.setAttribute("type", "module");
            }
            if (a.path) el.src = a.path;
        }

        if (!el) return;

        document.head.appendChild(el);

        el.onload = () => {
            document.head.removeChild(el);
            reportAssetUpdateProgress();
        };

        el.onerror = () => {
            document.head.removeChild(el);
            reportAssetUpdateProgress();
        };
    });
    if (ASSETS.length === 0) {
        handleUpdateProgress();
    }
}

/*#####################################
### CHECKING IF UPDATE IS AVAILABLE ###
#####################################*/
const update_available_dialog = ref<boolean>(false);

async function checkForUpdates() {
    if (performing_update.value) return;

    // 1. Odstęp min. 30min pomiędzy sprawdzeniami
    const LUCD = await Preferences.get({ key: PREFERENCES_KEYS.AUTOUPDATER_LAST_CHECK_DATE });
    if (LUCD.value && /^[1-9][0-9]{0,24}$/.test(LUCD.value)) {
        const PLUCD = parseInt(LUCD.value);
        if (!isNaN(PLUCD) && Date.now() - PLUCD < 30 * 60 * 1000) return;
    }

    // 2. Sprawdzenie wersji
    remote_document = await fetchHTML("/?v=" + Date.now());
    const REMOTE_VERSION = await getAppVersionFromHTML(remote_document);
    if (lt(import.meta.env.VITE_NPM_PACKAGE_VERSION, REMOTE_VERSION)) {
        update_available_dialog.value = true;
    }

    // 3. Raportowanie czasu sprawdzenia
    await Preferences.set({
        key: PREFERENCES_KEYS.AUTOUPDATER_LAST_CHECK_DATE,
        value: Date.now().toString()
    });
}

/*############################
### BROWSER REFOCUS EVENTS ###
############################*/
function onBrowserActiveStateChange(is_active: boolean) {
    if (is_active === true) {
        checkForUpdates();
    }
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
let events_mounted = false;
onMounted(async () => {
    if (Capacitor.isNativePlatform()) return;

    await waitForDocumentReady();
    await waitForMs(3000);

    if (await appIsFocused()) {
        checkForUpdates();
    }

    emitter.on("Browser::activestatechange", onBrowserActiveStateChange);
    events_mounted = true;
});
onUnmounted(() => {
    if (events_mounted) {
        emitter.off("Browser::activestatechange", onBrowserActiveStateChange);
    }
});
</script>
