<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="computed_size"
        :height="computed_size"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        :aria-labelledby="icon_component"
        role="presentation"
        class="custom-icon"
        v-bind="$attrs"
        :style="icon_styles"
    >
        <g :fill="computed_color">
            <component :is="icon_component" />
        </g>
    </svg>
    <div
        v-if="indicator && indicatorValue != '0'"
        class="custom-icon--indicator"
        :data-indicator-value="indicatorValue"
    >
        <span class="indicator-value">{{
            typeof indicatorValue === "number" && indicatorValue > 9 ? "9+" : indicatorValue
        }}</span>
    </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, defineComponent } from "vue";
import { isValidCssColor } from "@/helpers/validators";
import { colors } from "@/constants";

import ArchiveIcon from "@/components/icons/ArchiveIcon.vue";
import ArrowCollapse from "@/components/icons/ArrowCollapse.vue";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";
import ArrowExpand from "@/components/icons/ArrowExpand.vue";
import ArrowDownIconAlt from "@/components/icons/ArrowDownIconAlt.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/components/icons/ArrowRightIcon.vue";
import ArrowSquareLeft from "@/components/icons/ArrowSquareLeft.vue";
import ArrowSquareRight from "@/components/icons/ArrowSquareRight.vue";
import AttachmentIcon from "@/components/icons/AttachmentIcon.vue";
import BellIcon from "@/components/icons/BellIcon.vue";
import BookIcon from "@/components/icons/BookIcon.vue";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";
import CalendarFilledIcon from "@/components/icons/CalendarFilledIcon.vue";
import CallEndIcon from "@/components/icons/CallEndIcon.vue";
import CameraIcon from "@/components/icons/CameraIcon.vue";
import CameraOffIcon from "@/components/icons/CameraOffIcon.vue";
import CartIcon from "@/components/icons/CartIcon.vue";
import ChartIcon from "@/components/icons/ChartIcon.vue";
import ChatIcon from "@/components/icons/ChatIcon.vue";
import ChatFilledIcon from "@/components/icons/ChatFilledIcon.vue";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon.vue";
import CheckCircleFilledIcon from "@/components/icons/CheckCircleFilledIcon.vue";
import CheckCircleOutlineIcon from "@/components/icons/CheckCircleOutlineIcon.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import ChevronDownCircleIcon from "@/components/icons/ChevronDownCircleIcon.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import ChevronUpIcon from "@/components/icons/ChevronUpIcon.vue";
import ChevronsVerticalIcon from "@/components/icons/ChevronsVerticalIcon.vue";
import ClockIcon from "@/components/icons/ClockIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import CloseCircleOutlineIcon from "@/components/icons/CloseCircleOutlineIcon.vue";
import CogIcon from "@/components/icons/CogIcon.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import CreditCardIcon from "@/components/icons/CreditCardIcon.vue";
import DeclineIcon from "@/components/icons/DeclineIcon.vue";
import DeclineCircleIcon from "@/components/icons/DeclineCircleIcon.vue";
import DocumentIcon from "@/components/icons/DocumentIcon.vue";
import DocumentFilledIcon from "@/components/icons/DocumentFilledIcon.vue";
import DoubleQuotesIcon from "@/components/icons/DoubleQuotesIcon.vue";
import EmojiIcon from "@/components/icons/EmojiIcon.vue";
import EyeOutlineIcon from "@/components/icons/EyeOutlineIcon.vue";
import EyeOffOutlineIcon from "@/components/icons/EyeOffOutlineIcon.vue";
import ExclamationMarkIcon from "@/components/icons/ExclamationMarkIcon.vue";
import FilterIcon from "@/components/icons/FilterIcon.vue";
import Fullscreen from "../icons/Fullscreen.vue";
import FullscreenExit from "../icons/FullscreenExit.vue";
import GoogleIcon from "@/components/icons/GoogleIcon.vue";
import GraduationIcon from "@/components/icons/GraduationIcon.vue";
import HelpChatIcon from "@/components/icons/HelpChatIcon.vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import InfoCircleIcon from "@/components/icons/InfoCircleIcon.vue";
import InfoCircleOutlineIcon from "@/components/icons/InfoCircleOutlineIcon.vue";
import LetterIcon from "@/components/icons/LetterIcon.vue";
import LocationIcon from "@/components/icons/LocationIcon.vue";
import LockIcon from "@/components/icons/LockIcon.vue";
import MagnifyingGlassIcon from "@/components/icons/MagnifyingGlassIcon.vue";
import MagnifyingGlassMinusIcon from "@/components/icons/MagnifyingGlassMinusIcon.vue";
import MagnifyingGlassPlusIcon from "@/components/icons/MagnifyingGlassPlusIcon.vue";
import ManIcon from "@/components/icons/ManIcon.vue";
import MedalIcon from "@/components/icons/MedalIcon.vue";
import MenuBurgerIcon from "@/components/icons/MenuBurgerIcon.vue";
import MenuDotsHorizontalIcon from "@/components/icons/MenuDotsHorizontalIcon.vue";
import MenuDotsVerticalIcon from "@/components/icons/MenuDotsVerticalIcon.vue";
import MicIcon from "@/components/icons/MicIcon.vue";
import MicrophoneIcon from "@/components/icons/MicrophoneIcon.vue";
import MicrophoneOffIcon from "@/components/icons/MicrophoneOffIcon.vue";
import MoneyIcon from "@/components/icons/MoneyIcon.vue";
import MonitorOff from "../icons/MonitorOff.vue";
import MonitorShare from "../icons/MonitorShare.vue";
import NoImageIcon from "@/components/icons/NoImageIcon.vue";
import PaperPlaneIcon from "@/components/icons/PaperPlaneIcon.vue";
import PencilIcon from "@/components/icons/PencilIcon.vue";
import PhoneIcon from "@/components/icons/PhoneIcon.vue";
import PhoneDownIcon from "@/components/icons/PhoneDownIcon.vue";
import PictureIcon from "@/components/icons/PictureIcon.vue";
import PinIcon from "@/components/icons/PinIcon.vue";
import PinOffIcon from "../icons/PinOffIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import PlusCircleIcon from "@/components/icons/PlusCircleIcon.vue";
import RecordIcon from "@/components/icons/RecordIcon.vue";
import RecordOutlinedIcon from "@/components/icons/RecordOutlinedIcon.vue";
import SendIcon from "@/components/icons/SendIcon.vue";
import SmartphoneIcon from "@/components/icons/SmartphoneIcon.vue";
import SnapshotIcon from "@/components/icons/SnapshotIcon.vue";
import StarIcon from "@/components/icons/StarIcon.vue";
import StarFilledIcon from "@/components/icons/StarFilledIcon.vue";
import StopIcon from "@/components/icons/StopIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import UserIcon from "@/components/icons/UserIcon.vue";
import WarningCircleOutlineIcon from "@/components/icons/WarningCircleOutlineIcon.vue";

type IconComponents = {
    [key: string]: ReturnType<typeof defineComponent>;
};

const ICON_COMPONENTS: IconComponents = {
    archive: ArchiveIcon,
    arrowCollapse: ArrowCollapse,
    arrowDown: ArrowDownIcon,
    arrowExpand: ArrowExpand,
    arrowDownAlt: ArrowDownIconAlt,
    arrowLeft: ArrowLeftIcon,
    arrowRight: ArrowRightIcon,
    arrowSquareLeft: ArrowSquareLeft,
    arrowSquareRight: ArrowSquareRight,
    attachment: AttachmentIcon,
    bell: BellIcon,
    book: BookIcon,
    calendar: CalendarIcon,
    calendarFilled: CalendarFilledIcon,
    callEnd: CallEndIcon,
    camera: CameraIcon,
    cameraOff: CameraOffIcon,
    cart: CartIcon,
    chart: ChartIcon,
    chat: ChatIcon,
    chatFilled: ChatFilledIcon,
    check: CheckIcon,
    checkCircle: CheckCircleIcon,
    checkCircleFilled: CheckCircleFilledIcon,
    checkCircleOutline: CheckCircleOutlineIcon,
    chevronDown: ChevronDownIcon,
    chevronDownCircle: ChevronDownCircleIcon,
    chevronLeft: ChevronLeftIcon,
    chevronRight: ChevronRightIcon,
    chevronUp: ChevronUpIcon,
    chevronsVertical: ChevronsVerticalIcon,
    clock: ClockIcon,
    close: CloseIcon,
    closeCircleOutline: CloseCircleOutlineIcon,
    cog: CogIcon,
    copy: CopyIcon,
    creditCard: CreditCardIcon,
    decline: DeclineIcon,
    declineCircle: DeclineCircleIcon,
    document: DocumentIcon,
    documentFilled: DocumentFilledIcon,
    doubleQuotes: DoubleQuotesIcon,
    emoji: EmojiIcon,
    eyeOutline: EyeOutlineIcon,
    eyeOffOutline: EyeOffOutlineIcon,
    exclamationMark: ExclamationMarkIcon,
    filter: FilterIcon,
    fullscreen: Fullscreen,
    fullscreenExit: FullscreenExit,
    google: GoogleIcon,
    graduation: GraduationIcon,
    home: HomeIcon,
    helpChat: HelpChatIcon,
    infoCircle: InfoCircleIcon,
    infoCircleOutline: InfoCircleOutlineIcon,
    letter: LetterIcon,
    location: LocationIcon,
    lock: LockIcon,
    magnifyingGlass: MagnifyingGlassIcon,
    magnifyingGlassMinus: MagnifyingGlassMinusIcon,
    magnifyingGlassPlus: MagnifyingGlassPlusIcon,
    man: ManIcon,
    medal: MedalIcon,
    menuBurger: MenuBurgerIcon,
    menuDotsHorizontal: MenuDotsHorizontalIcon,
    menuDotsVertical: MenuDotsVerticalIcon,
    mic: MicIcon,
    microphone: MicrophoneIcon,
    microphoneOff: MicrophoneOffIcon,
    money: MoneyIcon,
    monitorOff: MonitorOff,
    monitorShare: MonitorShare,
    noImage: NoImageIcon,
    paperPlane: PaperPlaneIcon,
    pencil: PencilIcon,
    phone: PhoneIcon,
    phoneDown: PhoneDownIcon,
    picture: PictureIcon,
    pin: PinIcon,
    pinOff: PinOffIcon,
    plus: PlusIcon,
    plusCircle: PlusCircleIcon,
    record: RecordIcon,
    recordOutlined: RecordOutlinedIcon,
    send: SendIcon,
    smartphone: SmartphoneIcon,
    snapshot: SnapshotIcon,
    star: StarIcon,
    starFilled: StarFilledIcon,
    stop: StopIcon,
    trash: TrashIcon,
    user: UserIcon,
    warningCircleOutline: WarningCircleOutlineIcon
};

const DEFAULT_SIZE = 24;

defineOptions({
    inheritAttrs: false
});
const props = withDefaults(
    defineProps<{
        size?: string | number;
        name: string;
        color?: string;
        indicator?: boolean;
        indicatorValue?: string | number;
    }>(),
    {
        color: "currentColor",
        indicator: false,
        indicatorValue: ""
    }
);

const computed_size: ComputedRef<number> = computed(() => {
    return props.size ? parseInt(String(props.size)) : DEFAULT_SIZE;
});

const icon_styles = computed(() => {
    const O: Record<string, string> = {};

    if (props.size !== undefined) {
        O.width = `${props.size}px`;
        O.height = `${props.size}px`;
    }

    return O;
});

const computed_color: ComputedRef<string> = computed(() => {
    if (isValidCssColor(props.color)) return props.color;
    const c = colors[props.color];
    if (c) return c;
    return "currentColor";
});

const icon_component = computed(() => {
    const icon_name = props.name.replace(/-./g, x => x[1].toUpperCase());
    return ICON_COMPONENTS[icon_name];
});
</script>
