<template>
    <g>
        <path
            d="M 12 1 A 11 11 0 0 0 1 12 A 11 11 0 0 0 12 23 A 11 11 0 0 0 23 12 A 11 11 0 0 0 12 1 z M 12 2.8945312 A 9.1063471 9.1063471 0 0 1 21.105469 12 A 9.1063471 9.1063471 0 0 1 12 21.105469 A 9.1063471 9.1063471 0 0 1 2.8945312 12 A 9.1063471 9.1063471 0 0 1 12 2.8945312 z "
        />
        <path
            d="M 17.21293,7.3084638 A 1.042586,1.042586 0 0 0 16.47579,7.6139089 L 9.9148282,14.17487 7.5242113,11.784253 a 1.042586,1.042586 0 0 0 -1.4742818,0 1.042586,1.042586 0 0 0 0,1.474282 l 3.1277579,3.127757 a 1.0426902,1.0426902 0 0 0 1.4742816,0 l 7.298102,-7.2981015 a 1.042586,1.042586 0 0 0 0,-1.4742816 1.042586,1.042586 0 0 0 -0.737141,-0.3054451 z"
        />
    </g>
</template>
