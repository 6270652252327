<template>
    <button
        class="risify-checkbox-element"
        :class="{
            ['risify-checkbox-element--' + element_size]: true,
            'risify-checkbox-element--checked': is_checked,
            'risify-checkbox-element--invalid': error,
            'risify-checkbox-element--disabled': disabled
        }"
        autocomplete="off"
        :disabled="!is_clickable"
        :tabindex="!is_clickable ? '-1' : '0'"
        @click.stop="toggleElement"
    >
        <div class="risify-checkbox-element__fill"></div>
        <svg
            class="risify-checkbox-element__icon"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M 12.933594,3.0019531 A 1,1 0 0 0 12.248047,3.3417969 L 5.9511719,10.537109 3.7070313,8.2929688 a 1,1 0 0 0 -1.4140625,0 1,1 0 0 0 0,1.4140625 l 2.9999999,2.9999997 a 1.0001,1.0001 0 0 0 1.4589844,-0.04883 L 13.751953,4.6582031 A 1,1 0 0 0 13.658203,3.2480469 1,1 0 0 0 12.933594,3.0019531 Z"
            />
        </svg>
    </button>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";

export type RisifyCheckboxElementValue<T> = T | T[] | undefined;
export type RisifyCheckboxElementProps<T> = {
    disabled?: boolean;
    readonly?: boolean;
    multiple?: boolean;

    error?: boolean;

    trueValue: T;
    modelValue: RisifyCheckboxElementValue<T>;

    xSmall?: boolean;
    small?: boolean;
    large?: boolean;
    xLarge?: boolean;
};

/*###########
### SETUP ###
###########*/
const props = withDefaults(defineProps<RisifyCheckboxElementProps<T>>(), {
    disabled: false,
    readonly: false,
    multiple: false,
    error: false
});

const emit = defineEmits<{
    (e: "update:modelValue", v: RisifyCheckboxElementValue<T>): void;
    (e: "click", event: MouseEvent | PointerEvent): void;
}>();

/*###############
### VARIABLES ###
###############*/

/*##############
### COMPUTED ###
##############*/
const is_checked = computed(() => {
    if (props.multiple !== true) {
        return props.modelValue === props.trueValue;
    } else {
        return (
            Array.isArray(props.modelValue) &&
            props.modelValue.findIndex(it => it === props.trueValue) !== -1
        );
    }
});
const element_size = computed(() => {
    if (props.xSmall === true) {
        return "xsmall";
    }
    if (props.small === true) {
        return "small";
    }
    if (props.large === true) {
        return "large";
    }
    if (props.xLarge === true) {
        return "xlarge";
    }

    return "regular";
});
const is_clickable = computed(() => props.disabled !== true && props.readonly !== true);

/*#############
### METHODS ###
#############*/
function toggleElement(e: MouseEvent | PointerEvent) {
    if (props.disabled) return;
    emit("click", e);

    let nv = null;
    if (is_checked.value) {
        if (props.multiple === true) {
            nv = JSON.parse(JSON.stringify(props.modelValue));
            nv.splice(nv.indexOf(props.trueValue), 1);
        } else {
            nv = undefined;
        }
    } else {
        if (props.multiple) {
            nv = JSON.parse(JSON.stringify(props.modelValue));
            nv.push(props.trueValue);
        } else {
            nv = props.trueValue;
        }
    }

    emit("update:modelValue", nv);
}

/*##############
### EXPPOSES ###
##############*/
defineExpose({
    toggleElement,
    is_checked
});
</script>
