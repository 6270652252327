import { UserRole } from "./user";
import { ApiPaginatedResponse, ApiSuccessResponse } from "./api-http-requests";

export enum SurveyType {
    THS_FEEDBACK = "ths_feedback"
}

export enum SurveyQuestionType {
    SINGLE_CHOICE = "single_choice",
    MULTIPLE_CHOICE = "multiple_choice",
    TEXT = "text",
    NPS = "nps",
    SIMPLE_FEEDBACK = "sfdb"
}

export enum  SurveySfdbQuestionAnswerType {
    AFFIRMATIVE = "affirmative",
    NEGATIVE = "negative"
}

export enum SurveyStatus {
    DRAFT = "draft",
    PUBLISHED = "published"
}

export enum SurveyQuestionSingleChoiceLayout {
    LIST_1COL = "list_1col",
    LIST_2COL = "list_2col"
}

export enum SurveyQuestionMultipleChoiceLayout {
    LIST_1COL = "list_1col",
    LIST_2COL = "list_2col",
    CLOUD = "cloud"
}

export enum SurveyQuestionNpsLayout {
    DEFAULT = "default",
    STARS = "stars"
}

export enum SurveySfdbQuestionAnswerDescriptionMode {
    REQUIRED = "required",
    OPTIONAL_VISIBLE = "optional_visible",
    OPTIONAL_HIDDEN = "optional_hidden"
};

export type SurveyQuestionChoice = {
    id: string;
    label: string;
    rtc_value: number;
};

export type Survey = {
    _id: string;
    name: string;
    type: SurveyType;
    filler_role: UserRole;
    title: string;
    description: string;
    questions: SurveyQuestion[];
    status: SurveyStatus;
    primary: boolean;
    c_date: number;
    m_date: number;
};

export type SurveyQuestionBase = {
    _id: string;
    title: string;
    rtc_enabled: boolean;
    rtc_weight: number;
};

export type SurveyQuestionSingleChoice = SurveyQuestionBase & {
    type: SurveyQuestionType.SINGLE_CHOICE;
    choices: SurveyQuestionChoice[];
    single_choice: {
        required: boolean;
        layout: SurveyQuestionSingleChoiceLayout;
    };
};

export type SurveyQuestionMultipleChoice = SurveyQuestionBase & {
    type: SurveyQuestionType.MULTIPLE_CHOICE;
    choices: SurveyQuestionChoice[];
    multiple_choice: {
        min_choices: number;
        max_choices: number;
        layout: SurveyQuestionMultipleChoiceLayout;
    };
};

export type SurveyQuestionText = SurveyQuestionBase & {
    type: SurveyQuestionType.TEXT;
    text: {
        min_length: number;
        max_length: number;
        autogrow: boolean;
        uc_source: boolean;
    };
};

export type SurveyQuestionNps = SurveyQuestionBase & {
    type: SurveyQuestionType.NPS;
    nps: {
        required: boolean;
        min_value: number;
        max_value: number;
        min_value_label: string;
        max_value_label: string;
        layout: "default" | "stars";
        affprog_invite_threshold: number;
    };
};

export type SurveyQuestionSfdb = SurveyQuestionBase & {
    type: SurveyQuestionType.SIMPLE_FEEDBACK;
    sfdb: {
        required: boolean;
        answer_affirmative_label: string;
        answer_affirmative_description_mode: SurveySfdbQuestionAnswerDescriptionMode;
        answer_affirmative_description_label: string;
        answer_negative_label: string;
        answer_negative_description_mode: SurveySfdbQuestionAnswerDescriptionMode;
        answer_negative_description_label: string;
        email_notification_recipients: string[];
        email_notification_triggers: SurveySfdbQuestionAnswerType[];
        answer_affirmative_rtc_value: number;
        answer_negative_rtc_value: number;
    };
};

export type SurveyQuestionValue = {
    question: string;
    values: string[];
};

export type SurveyQuestionExpose = {
    getValue: () => SurveyQuestionValue | null;
    validate: () => boolean | string;
    getAnchorElement: () => HTMLElement | undefined;
};

export type SurveyQuestion =
    | SurveyQuestionSingleChoice
    | SurveyQuestionMultipleChoice
    | SurveyQuestionText
    | SurveyQuestionNps
    | SurveyQuestionSfdb;

export type ApiSurveyResponse = ApiSuccessResponse & {
    survey: Survey;
};

export type ApiSurveysResponse = ApiPaginatedResponse & {
    surveys: Survey[];
};
