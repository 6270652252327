<template>
    <div
        class="chmarpc"
        ref="chmarpc"
        v-if="
            chatMessage.deleted === false &&
            chatMessage.message_type === ChatMessageMessageType.EVENT
        "
    >
        <!-- TYTUŁ ZDARZENIA Z PODZIAŁEM SYSTEM / USER -->
        <div class="chmarpc__title text-link-2 text-weight-semibold mb-2">
            <template v-if="chatMessage.author_type === ChatMessageAuthorType.USER">
                <template
                    v-if="
                        chatMessage.event.name ===
                        ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED
                    "
                >
                    {{ author_name }}
                    zaproponował(-a) nowy termin wizyty:
                </template></template
            >
            <template v-else-if="chatMessage.author_type === ChatMessageAuthorType.SYSTEM">
                {{ ChatMessageEventNameLabel[chatMessage.event.name] }}:
            </template>
        </div>

        <!-- SZCZEGÓŁY SPOTKANIA -->
        <Transition
            name="fade"
            mode="out-in"
        >
            <div
                class="chmarpc__loader skeleton-loader-box"
                v-if="!data_loaded"
            ></div>
            <div
                class="chmarpc__appointment"
                v-else
            >
                <div
                    v-if="arp"
                    :class="{ 'chmarpc-apptile': true, 'pa-3 pa-md-4': !noPadding }"
                >
                    <!-- NAZWA PRODUKTU -->
                    <div class="text-link-3 text-weight-semibold mb-1">
                        {{ arp.appointment.product.name }}
                    </div>

                    <!-- DATY I GODZINY -->
                    <!-- 1. POPRZEDNIA DATA I GODZINA SPOTKANIA (przed przełożeniem) -->
                    <div
                        v-if="
                            chatMessage.event.name ===
                            ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED
                        "
                        class="chmarpc-apptile-stime"
                    >
                        <div
                            class="chmarpc-apptile-stime__element chmarpc-apptile-stime__element--crossedout"
                        >
                            <CustomIcon
                                name="calendar"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div class="pr-2">
                                {{
                                    moment(arp.original_start_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("DD.MM.YYYY")
                                }}
                            </div>
                        </div>
                        <div
                            class="chmarpc-apptile-stime__element chmarpc-apptile-stime__element--crossedout"
                        >
                            <CustomIcon
                                name="clock"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div class="pr-2">
                                {{
                                    moment(arp.original_start_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm") +
                                    " - " +
                                    moment(arp.original_end_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm")
                                }}
                            </div>
                        </div>
                    </div>
                    <!-- 2. AKTUALNA DATA (przekreślona jeżeli spotkanie anulowane) -->
                    <div class="chmarpc-apptile-stime">
                        <div
                            class="chmarpc-apptile-stime__element"
                            :class="{
                                'chmarpc-apptile-stime__element--crossedout':
                                    arp.appointment.status === AppointmentStatus.CANCELED
                            }"
                        >
                            <CustomIcon
                                name="calendar"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div class="pr-2">
                                {{
                                    moment(arp.new_start_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("DD.MM.YYYY")
                                }}
                            </div>
                        </div>
                        <div
                            class="chmarpc-apptile-stime__element"
                            :class="{
                                'chmarpc-apptile-stime__element--crossedout':
                                    arp.appointment.status === AppointmentStatus.CANCELED
                            }"
                        >
                            <CustomIcon
                                name="clock"
                                size="12"
                                class="mr-1"
                            ></CustomIcon>
                            <div class="pr-2">
                                {{
                                    moment(arp.new_start_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm") +
                                    " - " +
                                    moment(arp.new_end_time)
                                        .tz(auth_store.getUserTimezone())
                                        .format("HH:mm")
                                }}
                            </div>
                        </div>
                    </div>

                    <!-- OPCJONALNY FOOTER Z CTA -->
                    <div
                        class="chmarpc-apptile__footer"
                        v-if="!hideActions"
                    >
                        <template
                            v-if="
                                auth_store.user &&
                                auth_store.user.role === UserRole.USER &&
                                arp.user === auth_store.user._id &&
                                arp.status === AppointmentRescheduleProposalStatus.PENDING
                            "
                        >
                            <!-- Przycisk do opłacenia (tylko, gdy appointment status jest unpaid) -->
                            <div class="chmarpc-apptile__cancelcta">
                                <RisifyButton
                                    small
                                    text
                                    icon
                                    color="dark-green"
                                    @click="onArpRejectClick"
                                >
                                    Wybierz inny termin
                                </RisifyButton>
                            </div>
                            <div class="chmarpc-apptile__acceptcta">
                                <RisifyButton
                                    small
                                    text
                                    icon
                                    color="green"
                                    :loading="accepting_arp"
                                    @click="
                                        () => {
                                            acceptArp();
                                            emit('click:action-button');
                                        }
                                    "
                                >
                                    <CustomIcon
                                        name="check"
                                        class="mr-1"
                                    />
                                    Akceptuj
                                </RisifyButton>
                            </div>
                        </template>
                        <div
                            v-else
                            class="text-link-3 text-md-body-2"
                        >
                            <template
                                v-if="arp.status === AppointmentRescheduleProposalStatus.PENDING"
                            >
                                Oczekiwanie na decyzję umawiającego wizytę
                            </template>
                            <template
                                v-else-if="
                                    arp.status === AppointmentRescheduleProposalStatus.ACCEPTED
                                "
                            >
                                Zaakceptowano
                            </template>
                            <template
                                v-else-if="
                                    arp.status === AppointmentRescheduleProposalStatus.CANCELED
                                "
                            >
                                Anulowano
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="chmarpc__nad mt-n1 text-link-3"
                >
                    Nie możesz wyświetlić tego elementu
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores/auth";
import { useFetchedElementsStore } from "@/stores/fetched-elements";

import moment from "@/helpers/moment";

import {
    ChatMessage,
    ChatMessageAuthorType,
    ChatMessageEventName,
    ChatMessageEventNameLabel,
    ChatMessageMessageType
} from "@/types/chat-message";
import { AppointmentStatus } from "@/types/appointment";
import { UserRole } from "@/types/user";

import RisifyButton from "@/components/buttons/RisifyButton.vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import { AppointmentRescheduleProposalStatus } from "@/types/appointment-reschedule-proposal";
import { httpJsonRequest } from "@/plugins/fetchApi";
import { Stopwatch } from "@/helpers/waiters";

/*###########
### SETUP ###
###########*/
const props = withDefaults(
    defineProps<{
        chatMessage: ChatMessage;
        noPadding?: boolean;
        hideActions?: boolean;
        externalRedirects?: boolean;
    }>(),
    {
        noPadding: false,
        hideActions: false,
        externalRedirects: false
    }
);
const emit = defineEmits<{
    (e: "click:action-button"): void;
}>();
const auth_store = useAuthStore();
const fetched_elements = useFetchedElementsStore();
const router = useRouter();

/*#########################
### IO-BASED DATA FETCH ###
#########################*/
const data_loaded = ref<boolean>(false);
const chmarpc = ref<HTMLElement>();
const chmarpc_id = ref<string>("");
function onIntersect(entries: IntersectionObserverEntry[]) {
    if (entries.length > 0 && entries[0].isIntersecting) {
        fetchData();
    }
}
const { stop: stopIoWatch } = useIntersectionObserver(chmarpc, onIntersect, { threshold: 0.1 });

async function fetchData() {
    if (
        props.chatMessage.deleted === false &&
        props.chatMessage.message_type === ChatMessageMessageType.EVENT
    ) {
        if (
            props.chatMessage.event.name ===
            ChatMessageEventName.APPOINTMENT_RESCHEDULE_PROPOSAL_CREATED
        ) {
            try {
                const R = await fetched_elements.getAppointmentRescheduleProposal(
                    props.chatMessage.event.subject_arp
                );
                chmarpc_id.value = R._id;
            } catch (err) {
                console.error(err);
            }

            data_loaded.value = true;
        }
    }

    stopIoWatch();
}

/*##########
### DATA ###
##########*/
const arp = computed(() => {
    return fetched_elements.retrieveAppointmentRescheduleProposal(chmarpc_id.value);
});

/*#####################
### DISPLAY HELPERS ###
#####################*/
const author_name = computed(() => {
    if (props.chatMessage.author_type === ChatMessageAuthorType.USER) {
        return props.chatMessage.user.first_name + " " + props.chatMessage.user.last_name;
    }
    return "";
});

/*###################
### ACCEPTING ARP ###
###################*/
const accepting_arp = ref<boolean>(false);

async function acceptArp() {
    if (accepting_arp.value || !arp.value) return;
    accepting_arp.value = true;

    const SW = new Stopwatch();

    try {
        await SW.waitUntil(500);

        await httpJsonRequest(`/appointment-reschedule-proposals/${arp.value._id}/accept`, {
            method: "POST"
        });
    } catch (err) {
        console.error(err);
    }

    accepting_arp.value = false;
}

/*###################
### REJECTING ARP ###
###################*/
function onArpRejectClick() {
    if (arp.value) {
        if (props.externalRedirects) {
            const RR = router.resolve({
                name: "wiz-fn1-appointment-reschedule",
                query: {
                    appointment: arp.value.appointment._id
                }
            });
            window.open(RR.href, "_blank");
        } else {
            router.push({
                name: "wiz-fn1-appointment-reschedule",
                query: {
                    appointment: arp.value.appointment._id
                }
            });
            emit("click:action-button");
        }
    }
}

/*#####################
### LIFECYCLE HOOKS ###
#####################*/
onUnmounted(async () => {
    stopIoWatch();
});
</script>
