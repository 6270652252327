<template>
    <div
        :class="{
            'user-tile': true,
            'user-tile--filled': filled,
            'user-tile--outlined': outlined,
            'user-tile--dense': dense,
            [`user-tile--${color}`]: true
        }"
    >
        <div class="user-tile__avatar">
            <Avatar
                :size="dense ? 32 : 40"
                :image-src="profileImage?.url_xs"
            />
        </div>
        <div
            class="user-tile__content"
            :class="{
                'user-tile__content--dense': dense
            }"
        >
            <div class="text-label-2 user-tile__label">
                {{ text }}
            </div>
            <div class="text-link-2 text-weight-semibold">
                {{ name }}
            </div>
            <div v-if="email" class="text-link-2 user-tile__email">
                {{ email }}
            </div>
            <div v-if="phone" class="text-link-2 user-tile__email">
                {{ phone }}
            </div>
        </div>
        <div
            v-if="actionButton"
            class="user-tile__action"
        >
            <slot name="action-slot">
                <RisifyButton
                    icon
                    color="gray"
                    x-small
                    @click="emit('click:action-button')"
                >
                    <CustomIcon :name="actionIcon" />
                </RisifyButton>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import Avatar from "@/components/generics/Avatar.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import { UserProfileImage } from "@/types/user";

/*###########
### SETUP ###
###########*/
type UserTileProps = {
    profileImage: UserProfileImage | undefined;
    actionButton?: boolean;
    actionIcon?: string;
    name?: string;
    text?: string;
    filled?: boolean;
    outlined?: boolean;
    dense?: boolean;
    color?: string;
    email?: string;
    phone?: string;
};
withDefaults(defineProps<UserTileProps>(), {
    filled: false,
    outlined: false,
    actionButton: true,
    actionIcon: "chevron-right",
    name: "",
    text: "",
    dense: false,
    color: "white",
    email: "",
    phone: ""
});

const emit = defineEmits<{
    (e: "click:action-button"): void;
}>();
</script>
