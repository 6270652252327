<template>
    <Notivue v-slot="item">
        <NotivueSwipe :item="item">
            <NewChatMessageNotification
                v-if="
                    item.props && item.props.notification_type === NotificationType.NEW_CHAT_MESSAGE
                "
                @clear="item.clear()"
                :chat-message="item.props.chat_message"
            />
            <div
                v-else
                class="ui-alert"
                :class="{
                    'ui-alert--promise': item.type === 'promise',
                    'ui-alert--wide': item.props.size && item.props.size === 'wide'
                }"
            >
                <div
                    v-if="item.type != 'promise'"
                    class="ui-alert__cbar"
                    :class="{
                        'ui-alert__cbar--success':
                            item.type == 'success' || item.type == 'promise-resolve',
                        'ui-alert__cbar--error':
                            item.type == 'error' || item.type == 'promise-reject',
                        'ui-alert__cbar--info': item.type == 'info',
                        'ui-alert__cbar--warning': item.type == 'warning'
                    }"
                ></div>
                <div class="ui-alert-main">
                    <div
                        class="ui-alert-main__icon"
                        :class="{
                            'ui-alert-main__icon--image': item.props.image_icon_src,
                            'ui-alert-main__icon--success':
                                item.type == 'success' || item.type == 'promise-resolve',
                            'ui-alert-main__icon--error':
                                item.type == 'error' || item.type == 'promise-reject',
                            'ui-alert-main__icon--info': item.type == 'info',
                            'ui-alert-main__icon--warning': item.type == 'warning'
                        }"
                    >
                        <LazyImage
                            v-if="item.props.image_icon_src"
                            :src="item.props.image_icon_src"
                            :lazy-src="item.props.image_icon_src"
                        />
                        <Spinner
                            v-else-if="item.type === 'promise'"
                            color="primary"
                            size="24"
                            width="4"
                        />
                        <CustomIcon
                            v-else
                            :name="
                                item.type === 'success' || item.type === 'promise-resolve'
                                    ? 'check-circle-outline'
                                    : item.type === 'error' || item.type === 'promise-reject'
                                    ? 'close-circle-outline'
                                    : item.type === 'info'
                                    ? 'info-circle-outline'
                                    : 'warning-circle-outline'
                            "
                        />
                    </div>
                    <div class="ui-alert-main__content">
                        <div
                            class="ui-alert-main__title text-h6"
                            v-if="item.title"
                        >
                            {{ item.title }}
                        </div>
                        <div
                            class="ui-alert-main__message text-body-2"
                            v-html="item.message"
                        ></div>
                    </div>
                    <div
                        class="ui-alert-main__defaction"
                        v-if="!item.props.hide_default_dismiss_button"
                    >
                        <RisifyButton
                            icon
                            text
                            color="dark-green"
                            @click="item.clear()"
                        >
                            <CustomIcon name="close" />
                        </RisifyButton>
                    </div>
                </div>
            </div>
        </NotivueSwipe>
    </Notivue>
</template>

<script setup lang="ts">
import { watch } from "vue";
import { Notivue, NotivueSwipe, useNotifications } from "notivue";
import CustomIcon from "@/components/generics/CustomIcon.vue";
import LazyImage from "@/components/generics/LazyImage.vue";
import NewChatMessageNotification from "@/partials/NewChatMessageNotification.vue";
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import Spinner from "@/components/loaders/Spinner.vue";
import { useBreakpoints } from "@/helpers/breakpoints";
import { getMaxZIndex, useDefaultLayout } from "@/helpers/layout";
import { useDialogs } from "@/helpers/dialogs";
import { NotificationType } from "@/types/chat-message";

const { is_desktop } = useBreakpoints();
const { is_bottom_navigation_visible } = useDefaultLayout();
const { any_dialog_opened } = useDialogs();

const nv_notifications = useNotifications();

watch(
    () => nv_notifications.entries.value.length,
    () => {
        if (nv_notifications.entries.value.length > 0) {
            document.documentElement.style.setProperty("--nv-z", (getMaxZIndex() + 1).toString());
        }
    }
);

watch(
    [is_desktop, is_bottom_navigation_visible, any_dialog_opened],
    () => {
        if (is_desktop.value) {
            document.documentElement.style.setProperty("--nv-root-top", "1.6rem");
            document.documentElement.style.setProperty("--nv-root-left", "1.6rem");
            document.documentElement.style.setProperty("--nv-root-right", "1.6rem");

            if (is_bottom_navigation_visible.value && !any_dialog_opened.value) {
                document.documentElement.style.setProperty("--nv-root-bottom", "11.2rem");
            } else {
                document.documentElement.style.setProperty("--nv-root-bottom", "1.6rem");
            }
        } else {
            document.documentElement.style.setProperty("--nv-root-top", "6.8rem");
            document.documentElement.style.setProperty("--nv-root-left", "1.2rem");
            document.documentElement.style.setProperty("--nv-root-right", "1.2rem");
            document.documentElement.style.setProperty("--nv-root-bottom", "1.2rem");
        }
    },
    {
        immediate: true
    }
);
</script>
