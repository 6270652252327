<template>
    <path
        d="M 12,1 C 5.9367185,1 1,5.9367185 1,12 1,18.063282 5.9367185,23 12,23 18.063282,23 23,18.063282 23,12 23,5.9367185 18.063282,1 12,1 Z m 0,2 c 4.982402,0 9,4.0175976 9,9 0,4.982402 -4.017598,9 -9,9 C 7.0175976,21 3,16.982402 3,12 3,7.0175976 7.0175976,3 12,3 Z"
    />
    <path
        d="m 5,12 c 0,3.854137 3.1458523,7 7,7 3.854139,0 7,-3.145861 7,-7 h -2 c 0,2.773254 -2.226746,5 -5,5 -2.7732656,0 -5,-2.226744 -5,-5 z"
    />
    <path
        d="M 15 6.5 C 14.177497 6.5 13.5 7.1774968 13.5 8 C 13.5 8.8225032 14.177497 9.5 15 9.5 C 15.822503 9.5 16.5 8.8225032 16.5 8 C 16.5 7.1774968 15.822503 6.5 15 6.5 z "
    />
    <path
        d="M 9 6.5 C 8.1774968 6.5 7.5 7.1774968 7.5 8 C 7.5 8.8225032 8.1774968 9.5 9 9.5 C 9.8225032 9.5 10.5 8.8225032 10.5 8 C 10.5 7.1774968 9.8225032 6.5 9 6.5 z "
    />
</template>
