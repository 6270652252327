"use strict";

import { CacheName } from "../types/cache";

const CACHE_VERSION = `risify_app_v${import.meta.env.VITE_APP_CACHE_VERSION}:`;
const CACHES = {
    assets: {
        name: `${CACHE_VERSION}assets`
    },
    api: {
        name: `${CACHE_VERSION}api`
    }
};

export function clearUnusedCaches() {
    return new Promise<void>(async (resolve, reject) => {
        try {
            const EXISTING_CACHE_KEYS = await caches.keys();
            await Promise.all(
                EXISTING_CACHE_KEYS.filter(key => !key.startsWith(CACHE_VERSION)).map(key =>
                    caches.delete(key)
                )
            );
            return resolve();
        } catch (err) {
            console.error("Unable to clear unused caches:");
            console.error(err);

            return reject(err);
        }
    });
}

export async function getCachedRequest(cache_name: CacheName, request: Request) {
    const SELECTED_CACHE = await caches.open(CACHES[cache_name].name);
    return SELECTED_CACHE.match(request);
}

export async function cacheRequest(
    cache_name: CacheName,
    request: Request,
    cloned_response: Response
) {
    const SELECTED_CACHE = await caches.open(CACHES[cache_name].name);
    return SELECTED_CACHE.put(request, cloned_response);
}
