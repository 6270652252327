import { ref } from "vue";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

export function useStatusBar() {
    const modified_statusbar_style = ref<boolean>(false);
    const previous_statusbar_style = ref<Style>(Style.Light);

    const setStatusBarStyle = async (style: Style) => {
        if (Capacitor.getPlatform() === "ios") {
            try {
                const SB_INFO = await StatusBar.getInfo();
                if (SB_INFO.style !== style) {
                    modified_statusbar_style.value = true;
                    previous_statusbar_style.value = SB_INFO.style;
                    await StatusBar.setStyle({ style });
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    const resetStatusBarStyle = async () => {
        if (Capacitor.getPlatform() === "ios" && modified_statusbar_style.value) {
            try {
                await StatusBar.setStyle({ style: previous_statusbar_style.value });
            } catch (e) {
                console.error(e);
            }
        }
    };

    return {
        setStatusBarStyle,
        resetStatusBarStyle
    };
}
