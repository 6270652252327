<template>
    <path
        d="M 30,3.4141 28.5859,2 2,28.5859 3.4141,30 l 2,-2 H 26 a 2.0027,2.0027 0 0 0 2,-2 V 5.4141 Z M 26,26 H 7.4141 l 7.7929,-7.793 2.3788,2.3787 a 2,2 0 0 0 2.8284,0 L 22,19 l 4,3.9973 z m 0,-5.8318 -2.5858,-2.5859 a 2,2 0 0 0 -2.8284,0 L 19,19.1682 16.623,16.7911 26,7.4141 Z"
        transform="matrix(0.57142857,0,0,0.57142857,2.8571429,2.8571429)"
    />
    <path
        d="m 6,22 v -3 l 5,-4.9966 1.3733,1.3733 1.4159,-1.416 -1.375,-1.375 a 2,2 0 0 0 -2.8284,0 L 6,16.1716 V 6 H 22 V 4 H 6 A 2.002,2.002 0 0 0 4,6 v 16 z"
        transform="matrix(0.57142857,0,0,0.57142857,2.8571429,2.8571429)"
    />
</template>
