<template>
    <div class="apdp">
        <RisifySelect
            outlined
            v-model="deadline_type"
            :items="[
                {
                    value: '24h',
                    text: 'Do 24h od zaplanowania'
                },
                {
                    value: '48h',
                    text: 'Do 48h od zaplanowania'
                },
                {
                    value: 'upto_24h',
                    text: 'Do 24h przed planowanym terminem wizyty'
                },
                {
                    value: 'upto_48h',
                    text: 'Do 48h przed planowanym terminem wizyty'
                },
                {
                    value: 'custom',
                    text: 'Do konkretnej daty'
                }
            ]"
            show-asterisk
        >
            <template #label>
                <div style="display: inline-flex; align-items: center">
                    <span>Czas na opłacenie wizyty</span>
                    <RisifyTooltip max-width="350px">
                        <template #content>
                            Każda wizyta dodana przez Ciebie ręcznie musi zostać opłacona przez
                            pacjenta w określonym czasie. Możesz go ustawić korzystając z
                            predefiniowanych opcji lub wybrać datę ręcznie. Bez względu na wybraną
                            opcję, czas na opłacenie można ustawić do maksymalnie 5 minut przed
                            rozpoczęciem zaplanowanej wizyty.
                        </template>
                        <template #activator>
                            <CustomIcon
                                name="info-circle-outline"
                                size="16"
                                class="ml-1"
                            ></CustomIcon>
                        </template>
                    </RisifyTooltip>
                </div>
            </template>
        </RisifySelect>
        <RisifyDatePicker
            outlined
            label="Własna data płatności"
            show-asterisk
            v-model="deadline_date"
            class="mt-4"
            :timezone="authStore.getUserTimezone()"
            ref="deadline_date_ref"
            :min-date="moment().tz(authStore.getUserTimezone()).format('YYYY-MM-DD')"
            :rules="[
                v => {
                    if (!v) {
                        return 'Proszę wybrać datę';
                    }

                    if (
                        moment
                            .tz(v, 'YYYY-MM-DDTHH:mm', authStore.getUserTimezone())
                            .isBefore(moment().tz(authStore.getUserTimezone()).add(5, 'minutes'))
                    ) {
                        return 'Pacjent musi mieć przynajmniej 5 minut na opłacenie wizyty';
                    }

                    return true;
                }
            ]"
            v-if="deadline_type === 'custom'"
        >
        </RisifyDatePicker>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";

import moment from "@/helpers/moment";

import RisifySelect from "@/components/form-inputs/RisifySelect.vue";
import RisifyTooltip from "../generics/RisifyTooltip.vue";
import CustomIcon from "../generics/CustomIcon.vue";
import RisifyDatePicker from "./RisifyDatePicker.vue";
import { RisifyInputBaseExpose } from "@/components/form-inputs/RisifyInput.vue";

export type AppointmentPaymentDeadlinePredefinedValue =
    | "24h"
    | "48h"
    | "upto_24h"
    | "upto_48h"
    | "custom";
export type AppointmentPaymentDeadlineExpose = RisifyInputBaseExpose & { getData: () => string };

const authStore = useAuthStore();

const deadline_type = ref<AppointmentPaymentDeadlinePredefinedValue>("upto_48h");

// const deadline_date = ref<string>("");
const deadline_date = ref<string>(
    moment().tz(authStore.getUserTimezone()).format("YYYY-MM-DDTHH:mm")
);
const deadline_date_ref = ref<RisifyInputBaseExpose>();

function validate() {
    const A: boolean[] = [
        deadline_type.value === "custom"
            ? deadline_date_ref.value
                ? deadline_date_ref.value.validate()
                : false
            : true
    ];

    return A.indexOf(false) === -1;
}

function resetValidation() {
    if (deadline_date_ref.value) {
        deadline_date_ref.value.resetValidation();
    }
}

function getData() {
    if (deadline_type.value === "custom") {
        return moment
            .tz(deadline_date.value, "YYYY-MM-DDTHH:mm", authStore.getUserTimezone())
            .toISOString();
    }
    return deadline_type.value;
}

defineExpose<AppointmentPaymentDeadlineExpose>({
    validate,
    resetValidation,
    getData
});
</script>
