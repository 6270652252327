<template>
    <div
        class="dialog-base-header px-6"
        :class="{
            'py-3 py-md-4': closeButton,
            'py-6': !closeButton
        }"
        v-bind="$attrs"
    >
        <div class="dialog-base-header__content text-h7 text-md-h6">
            <slot></slot>
        </div>
        <div
            class="dialog-base-header__cb ml-6"
            v-if="closeButton"
        >
            <RisifyButton
                @click="emit('close')"
                color="white"
                icon
                small
            >
                <CustomIcon
                    color="#1a1a1a"
                    name="close"
                />
            </RisifyButton>
        </div>
    </div>
    <div
        v-if="tabs && tabsItems"
        class="dialog-base-header-nav"
    >
        <div
            v-for="item in tabsItems"
            :key="item.value"
            :class="{
                'dialog-base-header-nav__tab': true,
                'text-h7': true,
                'dialog-base-header-nav__tab--active': modelTab === item.value
            }"
            @click="emit('update:modelTab', item.value)"
        >
            {{ item.text }}
        </div>
    </div>
</template>

<script setup lang="ts">
import RisifyButton from "@/components/buttons/RisifyButton.vue";
import CustomIcon from "@/components/generics/CustomIcon.vue";

const emit = defineEmits<{
    (e: "close"): void;
    (e: "update:modelTab", v: number): void;
}>();

defineOptions({
    inheritAttrs: false
});

defineModel("tab");

withDefaults(
    defineProps<{
        closeButton?: boolean;
        tabs?: boolean;
        tabsItems?: { text: string; value: number }[];
        modelTab?: number;
    }>(),
    {
        closeButton: false,
        tabs: false
    }
);
</script>
