<template>
    <path
        d="M 7.5,0 A 1.5,1.5 0 0 0 6,1.5 V 3 H 4.5 C 2.0324905,3 0,5.0324905 0,7.5 V 9 19.5 C 0,21.967531 2.0325177,24 4.5,24 h 15 C 21.967504,24 24,21.967504 24,19.5 V 9 7.5 C 24,5.0325177 21.967531,3 19.5,3 H 18 V 1.5 A 1.5,1.5 0 0 0 16.5,0 1.5,1.5 0 0 0 15,1.5 V 3 H 9 V 1.5 A 1.5,1.5 0 0 0 7.5,0 Z m -3,6 h 12 3 C 20.346261,6 21,6.6537789 21,7.5 V 9 19.5 C 21,20.346288 20.346288,21 19.5,21 H 4.5 C 3.6537789,21 3,20.346261 3,19.5 V 9 7.5 C 3,6.653806 3.6538061,6 4.5,6 Z"
    />
    <path
        d="M 7.5,15 A 1.5,1.5 0 0 0 6,16.5 1.5,1.5 0 0 0 7.5,18 H 12 A 1.5,1.5 0 0 0 13.5,16.5 1.5,1.5 0 0 0 12,15 Z"
    />
    <path
        d="M 12,9 A 1.5,1.5 0 0 0 10.5,10.5 1.5,1.5 0 0 0 12,12 h 4.5 A 1.5,1.5 0 0 0 18,10.5 1.5,1.5 0 0 0 16.5,9 Z"
    />
</template>
