<template>
    <div>
        <div @click="openDialog">
            <slot name="activator" />
        </div>

        <Dialog
            v-model="dialog"
            persistent
            width="600"
        >
            <template v-slot:header>
                <DialogBaseHeader
                    close-button
                    @close="dialog = false"
                >
                    {{ props.dialogTitle }}
                </DialogBaseHeader>
            </template>
            <template v-slot:default>
                <DialogBaseBody>
                    <RisifyTextField
                        v-model="dialog_search"
                        :label="searchLabel"
                        placeholder="Wpisz, aby wyszukać"
                        show-asterisk
                        @keydown.enter="_ => doSearch()"
                        @update:model-value="debounceSearch"
                        autofocus
                        outlined
                        class-name="mb-8"
                        dense
                        :loading="dialog_loading"
                    />
                    <div>
                        <div class="text-h7 mb-4">Znalezieni użytkownicy:</div>
                        <div
                            class="text-link-2"
                            v-if="dialog_list.length == 0 && !dialog_loading"
                        >
                            {{
                                dialog_search.length > 3
                                    ? "Nie znaleziono użytkowników"
                                    : "Wyszukaj, aby wyświetlić listę"
                            }}
                        </div>
                        <div
                            class="skeleton-loader-box mb-2"
                            style="height: 66px; width: 100%; border-radius: 6px"
                            v-if="dialog_loading"
                        ></div>
                        <UserTile
                            outlined
                            :profile-image="uu.profile_image"
                            :name="uu.first_name + ' ' + uu.last_name"
                            :text="dataType === 'users' ? 'Pacjent' : 'Terapeuta'"
                            :email="getUserEmail(uu)"
                            :phone="getUserPhone(uu)"
                            action-button
                            class="mb-2"
                            v-for="uu in dialog_list"
                            :key="uu._id"
                            style="cursor: pointer"
                            @click="
                                () => {
                                    user = uu;
                                    dialog = false;
                                    emitValue();
                                }
                            "
                            title="Wybierz"
                        >
                            <template v-slot:action-slot>
                                <RisifyButton
                                    color="green"
                                    text
                                    small
                                >
                                    WYBIERZ
                                </RisifyButton>
                            </template>
                        </UserTile>
                    </div>
                </DialogBaseBody>
            </template>
        </Dialog>
    </div>
</template>

<script setup lang="ts">
/*#############
### IMPORTS ###
#############*/

// Modules
import { ref, onMounted } from "vue";

// Types
import {
    UserPublicDataFragment,
    ApiUserUsersResponse,
    ApiUserTherapistsResponse,
    UserPublicDataWithContactInfo
} from "@/types/user";

// Helpers/plugins
import { httpJsonRequest } from "@/plugins/fetchApi";
import { Stopwatch } from "@/helpers/waiters";

// Components
import RisifyTextField from "../form-inputs/RisifyTextField.vue";
import RisifyButton from "../buttons/RisifyButton.vue";
import Dialog from "@/components/dialogs/Dialog.vue";
import DialogBaseHeader from "@/components/dialogs/DialogBaseHeader.vue";
import DialogBaseBody from "@/components/dialogs/DialogBaseBody.vue";
import UserTile from "@/components/tiles/UserTile.vue";

/*###########
### SETUP ###
###########*/

const props = withDefaults(
    defineProps<{
        dataType: "users" | "therapists";
        modelValue?: UserPublicDataFragment;
        searchLabel?: string;
        sourceUrl: string;
        searchParam: string;
        queryParams?: Record<string, string>;
        filterIds?: string[];
        dialogTitle?: string;
    }>(),
    {
        searchLabel: "Wyszukaj użytkownika",
        dialogTitle: "Wybierz użytkownika"
    }
);
const emit = defineEmits(["update:modelValue"]);

defineExpose({
    openDialog
});

/*##########
### DATA ###
##########*/

const user = ref<UserPublicDataFragment | null>(null);

const dialog = ref(false);
const dialog_loading = ref(false);
const dialog_search = ref<string>("");
const dialog_list = ref<UserPublicDataFragment[]>([]);

let debounce_timer: NodeJS.Timeout | null = null;

/*#############
### METHODS ###
#############*/
function emitValue() {
    emit("update:modelValue", user.value);
}

function debounceSearch() {
    if (debounce_timer) clearTimeout(debounce_timer);
    debounce_timer = setTimeout(doSearch, 500);
}

async function doSearch(force: boolean = false) {
    if (
        !force &&
        (dialog_loading.value || dialog_search.value.length < 3 || dialog_search.value.length > 63)
    )
        return;

    dialog_list.value = [];
    dialog_loading.value = true;

    const SW = new Stopwatch();

    try {
        // Składamy url
        const string_url = props.sourceUrl;
        const string_search = `?${props.searchParam}=${dialog_search.value}`;

        const array_query = [];
        if (props.queryParams) {
            for (const [key, value] of Object.entries(props.queryParams)) {
                array_query.push(`&${key}=${value}`);
            }
        }
        const url = string_url + string_search + array_query.join();

        // Szukamy
        if (props.dataType === "users") {
            const r = await httpJsonRequest<ApiUserUsersResponse>(url);
            await SW.waitUntil(500);

            dialog_list.value = r.users.filter(el => !props.filterIds?.includes(el._id));
        } else if (props.dataType === "therapists") {
            const r = await httpJsonRequest<ApiUserTherapistsResponse>(url);
            await SW.waitUntil(500);

            dialog_list.value = r.therapists.filter(el => !props.filterIds?.includes(el._id));
        }
    } catch (err) {
        console.error(err);
    }

    dialog_loading.value = false;
}

function openDialog() {
    dialog.value = true;
}

function getUserPhone(user: UserPublicDataWithContactInfo): string {
    if (props.dataType !== "users") return "";
    if (user && user.phone_cc && user.phone) {
        return `+${user.phone_cc} ${user.phone}`;
    }
    return "";
}

function getUserEmail(user: UserPublicDataWithContactInfo): string {
    if (props.dataType !== "users") return "";
    return user?.email ?? "";
}

onMounted(() => {
    doSearch(true);
});
</script>
