<template>
    <div class="progress-bar">
        <div class="progress-bar__container">
            <div
                class="progress-bar__fill"
                :style="{ width: computedWidth }"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface ProgressBarProps {
    value: number;
}

const props = withDefaults(defineProps<ProgressBarProps>(), {
    value: 0
});

const limitedValue = computed(() => Math.min(100, Math.max(0, props.value)));
const computedWidth = computed(() => `${limitedValue.value}%`);
</script>
