import { ApiSuccessResponse } from "./api-http-requests";
import { Appointment } from "./appointment";

export enum AppointmentRescheduleProposalStatus {
    PENDING = "pending",
    ACCEPTED = "accepted",
    CANCELED = "canceled"
}

export type AppointmentRescheduleProposal = {
    _id: string;
    user: string;
    appointment: Pick<
        Appointment,
        "_id" | "product" | "host" | "start_time" | "duration" | "status"
    >;
    original_start_time: number;
    original_end_time: number;
    new_start_time: number;
    new_end_time: number;
    status: AppointmentRescheduleProposalStatus;
    expdate: number;
    reason: string;
    c_date: number;
    m_date: number;
};

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiAppointmentRescheduleProposalResponse = ApiSuccessResponse & {
    appointment_reschedule_proposal: AppointmentRescheduleProposal;
};
