<template>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM20 6L4 6V18H20V6Z"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5858 10C16.3668 9.21895 17.6332 9.21895 18.4142 10L21.7071 13.2929L20.2929 14.7071L17 11.4142L12.4142 16C11.6332 16.7811 10.3668 16.781 9.58578 16L8 14.4142L3.70711 18.7071L2.29289 17.2929L6.58579 13C7.36684 12.219 8.63316 12.219 9.41421 13L11 14.5858L15.5858 10Z"
    />
    <path
        d="M12 9C12 9.55228 11.5523 10 11 10C10.4477 10 10 9.55228 10 9C10 8.44772 10.4477 8 11 8C11.5523 8 12 8.44772 12 9Z"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 8.5C10.7239 8.5 10.5 8.72386 10.5 9C10.5 9.27614 10.7239 9.5 11 9.5C11.2761 9.5 11.5 9.27614 11.5 9C11.5 8.72386 11.2761 8.5 11 8.5ZM9.5 9C9.5 8.17157 10.1716 7.5 11 7.5C11.8284 7.5 12.5 8.17157 12.5 9C12.5 9.82843 11.8284 10.5 11 10.5C10.1716 10.5 9.5 9.82843 9.5 9Z"
    />
</template>
