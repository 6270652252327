import { ApiCursorPaginatedResponse, ApiSuccessResponse } from "./api-http-requests";
import { Appointment } from "./appointment";
import { ChatMessage } from "./chat-message";
import { ProductTypeAppointment } from "./product";
import { UserPublicDataFragment, UserTherapistInfo } from "./user";

export enum ChatType {
    THUS_APPOINTMENT_PRODUCT = "thusappr",
    APPOINTMENT_VIDEOCALL = "appvc"
}

export type ChatMessageLogEntry = {
    mid: string;
    ts: number;
};

export const LAST_DISPLAYED_CHAT_PREFERENCES_KEY = "LastDisplayedChatId";

type ChatBase = {
    _id: string;
    users: (UserPublicDataFragment & { therapist_info?: UserTherapistInfo })[];
    kicked_users: UserPublicDataFragment[];
    owner: string;
    host: string;
    last_message?: ChatMessage;
    ld_message: Record<string, ChatMessageLogEntry>;
    lr_message: Record<string, ChatMessageLogEntry>;
    expdate?: number;
    la_date: number;
    c_date: number;
    m_date: number;
};

export type ChatThusappr = ChatBase & {
    type: ChatType.THUS_APPOINTMENT_PRODUCT;
    product: Pick<ProductTypeAppointment, "_id" | "name" | "type" | "subtype" | "appointment">;
};
export type ChatAppvc = ChatBase & {
    type: ChatType.APPOINTMENT_VIDEOCALL;
    appointment: Pick<Appointment, "start_time" | "duration" | "end_time"> & {
        product: Pick<ProductTypeAppointment, "_id" | "name" | "type" | "subtype" | "appointment">;
    };
};
export type Chat = ChatThusappr | ChatAppvc;

// #####################
// ### HTTP REQUESTS ###
// #####################
export type ApiChatsResponse<T = Chat> = ApiCursorPaginatedResponse & {
    chats: T;
};
export type ApiChatResponse<T = Chat> = ApiSuccessResponse & {
    chat: T;
};
