<template>
    <path
        d="M15.8392 16.8198L16.9366 15.0389L18.9172 14.3634L19.1366 12.2833L20.6267 10.8163L19.9183 8.84728L20.6267 6.8782L19.1367 5.41125L18.9172 3.33113L16.9367 2.65566L15.8392 0.874734L13.7633 1.12538L12 0L10.2366 1.12547L8.16075 0.874828L7.06326 2.6557L5.0827 3.33117L4.86328 5.4113L3.37317 6.87825L4.08164 8.84733L3.37317 10.8164L4.86323 12.2833L5.08265 14.3635L7.06322 15.039L8.1607 16.8198L10.2366 16.5692L12 17.6947L13.7633 16.5692L15.8392 16.8198ZM5.93297 8.84733C5.93297 5.502 8.65462 2.78034 12 2.78034C15.3453 2.78034 18.0669 5.502 18.0669 8.84733C18.0669 12.1927 15.3453 14.9143 12 14.9143C8.65462 14.9143 5.93297 12.1927 5.93297 8.84733Z"
    />
    <path
        d="M12.0001 4.1875C9.4306 4.1875 7.34021 6.27789 7.34021 8.84734C7.34021 11.4168 9.4306 13.5072 12.0001 13.5072C14.5695 13.5072 16.6599 11.4168 16.6599 8.84734C16.6599 6.27789 14.5695 4.1875 12.0001 4.1875Z"
    />
    <path
        d="M9.90461 18.0267L7.43524 18.3248L6.13057 16.2076L5.68703 16.0564L3.77393 22.0361L7.21807 21.8466L9.91268 24.0001L11.4984 19.0439L9.90461 18.0267Z"
    />
    <path
        d="M17.8694 16.2077L16.5647 18.3248L14.0953 18.0267L12.5016 19.0439L14.0873 24.0001L16.7819 21.8466L20.226 22.0361L18.3129 16.0564L17.8694 16.2077Z"
    />
</template>
