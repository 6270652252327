<template>
    <component
        :is="tag"
        :href="href"
        :to="to"
        class="risify-button"
        :class="{
            ['risify-button--' + color]: true,
            ['risify-button--' + button_type]: true,
            ['risify-button--' + button_size]: true,
            'risify-button--block': block === true,
            'risify-button--disabled': disabled === true,
            'risify-button--icon': icon === true
        }"
        @click="handleClick"
        :tabindex="is_clickable ? 0 : -1"
    >
        <span
            class="risify-button__content"
            :class="{
                'risify-button__content--hidden':
                    button_type == 'text' && loading && disabled !== true
            }"
        >
            <slot></slot>
        </span>
        <Transition
            name="fade"
            appear
        >
            <div
                class="risify-button__loader"
                v-if="loading && disabled !== true"
            >
                <Spinner
                    color="currentColor"
                    :size="button_type == 'text' ? 20 : 24"
                    width="4"
                />
            </div>
        </Transition>
    </component>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from "vue-router";
import { BaseColor } from "@/types/general";
import { computed } from "vue";
import Spinner from "@/components/loaders/Spinner.vue";

/*###################
### SETUP & HOOKS ###
###################*/
const props = withDefaults(
    defineProps<{
        tag?: "button" | "a" | "router-link";
        href?: string;
        to?: string | RouteLocationRaw;
        color?: BaseColor;
        outlined?: boolean;
        text?: boolean;
        plain?: boolean;
        small?: boolean;
        xSmall?: boolean;
        large?: boolean;
        block?: boolean;
        disabled?: boolean;
        loading?: boolean;
        icon?: boolean;
    }>(),
    {
        tag: "button",
        color: "yellow"
    }
);

const emit = defineEmits<{
    (e: "click", native_event: PointerEvent): void;
}>();

/*##############
### COMPUTED ###
##############*/
const button_type = computed(() => {
    if (props.outlined) {
        return "outlined";
    }
    if (props.text) {
        return "text";
    }
    if (props.plain) {
        return "plain";
    }
    return "filled";
});
const button_size = computed(() => {
    if (props.xSmall) {
        return "xsmall";
    }
    if (props.small) {
        return "small";
    }
    if (props.large) {
        return "large";
    }
    return "regular";
});
const is_clickable = computed(() => {
    return props.disabled !== true && props.loading !== true;
});

/*#############
### METHODS ###
#############*/
function handleClick(e: PointerEvent) {
    if (!is_clickable.value) return;
    emit("click", e);
}
</script>
