<template>
    <div
        class="bottom-navigation"
        :class="{
            'bottom-navigation--more-than-four': computed_navigation_items.length > 4 && is_mobile
        }"
    >
        <router-link
            v-for="item in computed_navigation_items"
            :key="item.route"
            :to="{ name: item.route }"
            class="bottom-navigation-btn"
            :class="{
                'bottom-navigation-btn--active': item.route && isRouteActive(item.route),
                'bottom-navigation-btn__indicator':
                    item.indicator && chats_store.getUnreadChats() > 0
            }"
            :style="`--chat-unread-msgs: '${chats_store.getUnreadChats()}'`"
            @click.prevent="item.handler ? item.handler() : null"
        >
            <CustomIcon
                color="primary_dark"
                :name="
                    item.route === 'chats' && isRouteActive(item.route) ? 'chat-filled' : item.icon
                "
            />
            <span class="bottom-navigation-btn__label">{{ item.label }}</span>
        </router-link>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import router from "@/plugins/router";
import { useAuthStore } from "@/stores/auth";
import { useBreakpoints } from "@/helpers/breakpoints";
import { useChatsStore } from "@/stores/chats";

import CustomIcon from "@/components/generics/CustomIcon.vue";
import { UserRole } from "@/types/user";

/*###############
### SETUP ###
############## */
interface NavigationItem {
    icon: string;
    label: string;
    route: string;
    handler?: () => void;
    indicator?: boolean;
}

const auth_store = useAuthStore();
const chats_store = useChatsStore();
const { is_mobile } = useBreakpoints();

const profile_path = computed(() => {
    return is_mobile.value ? "my-account" : "my-account-general-data";
});

/*###############
### VARIABLES ###
############## */
const user_navigation_items = ref<NavigationItem[]>([
    {
        icon: "home",
        label: "pulpit",
        route: "home"
    },
    {
        icon: "calendar",
        label: "wizyty",
        route: "appointments"
    },
    {
        icon: "chat",
        label: "chat",
        route: "chats",
        handler: loadChat,
        indicator: true
    },
    {
        icon: "book",
        label: "wiedza",
        route: "knowledge-blog"
    }
]);

const therapist_navigation_items = ref<NavigationItem[]>([
    {
        icon: "home",
        label: "pulpit",
        route: "home"
    },
    {
        icon: "calendar",
        label: "kalendarz",
        route: "appointments"
    },
    {
        icon: "chat",
        label: "chat",
        route: "chats",
        handler: loadChat,
        indicator: true
    },
    {
        icon: "chart",
        label: "rozliczenia",
        route: "therapist-settlements"
    },
    {
        icon: "user",
        label: "profil",
        route: profile_path.value
    }
]);

const computed_navigation_items = computed(() => {
    return auth_store.user && auth_store.user.role === UserRole.THERAPIST
        ? therapist_navigation_items.value
        : user_navigation_items.value;
});

/*###############
### METHODS ###
############## */
const getBasePath = (path: string): string => {
    const segments = path.split("/");
    return segments.length > 1 ? `/${segments[1]}` : "/";
};

const isRouteActive = (itemRoute: string): boolean => {
    const route_path = router.resolve({ name: itemRoute }).href;
    const base_path = getBasePath(route_path);

    const current_base_path = getBasePath(router.currentRoute.value.path);
    return current_base_path === base_path;
};

async function loadChat() {
    if (!auth_store.user || auth_store.user.role === UserRole.THERAPIST) {
        router.push({ name: "chats" });
        return;
    }

    // const LAST_CHAT_ID = await Preferences.get({ key: LAST_DISPLAYED_CHAT_PREFERENCES_KEY });

    if (chats_store.chats.length === 1) {
        // router.push({ name: "chat", params: { id: LAST_CHAT_ID.value } });
        router.push({ name: "chat", params: { id: chats_store.chats[0]._id } });
    } else {
        router.push({ name: "chats" });
    }
    // router.push({ name: "chats" });
    // return;
}
</script>
